import PropTypes from 'prop-types';

const Details = (props) => (
  <div
    className="mt-3 event-details-container"
    dangerouslySetInnerHTML={{ __html: props.details }}></div>
);

export default Details;

Details.propTypes = {
  details: PropTypes.string.isRequired
};
