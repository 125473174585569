import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Calendar, Location, Price } from './../Icons/Icons';
import ButtonMain from '../ButtonMain/ButtonMain';
import PurchaseButton from '../ButtonMain/PurchaseButton';
import { EventStateEnum } from '../../enums/enums';

import CalendarDownload from '../Social/CalendarDownload';
import FacebookShare from '../Social/FacebookShare';
import TwitterShare from '../Social/TwitterShare';
import ContentModal from '../ContentModal/ContentModal';
import { Col, Row, Button } from 'react-bootstrap';
import pushDataLayer from '../../utilities/analytics';

const SeriesTile = (props) => {
  const [showOfferTermsModal, setShowOfferTermsModal] = useState(false);

  const dataEvent = 'navigationClick';
  const dataLocation = 'body';
  const dataEventPurchaseNow = 'eventTileClick';
  const dataTileAction = 'Event Tile Purchase Click';

  const location = useLocation();

  useEffect(() => {
    if (location.hash == '#additional') {
      setShowOfferTermsModal(true);
    }
  }, []);

  return (
    <>
      <Col className="bg-light my-4 p-3">
        <h2 className="h6">{props.title}</h2>
        <Row>
          <Col md="auto" className="flex-row d-flex">
            <Calendar />
            <p className="ms-2">{props.eventDateText}</p>
          </Col>
          <Col md="auto" className="flex-row d-flex">
            <Location />
            <p className="ms-2">{props.locationText}</p>
          </Col>
          <Col md="auto" className="flex-row d-flex">
            <Price />
            <p className="ms-2">{props.priceString}</p>
          </Col>
        </Row>
        <Row>
          <Col lg="auto" xs={12} className="py-1">
            {/* Sold out or purchase now */}
            {props.eventState === EventStateEnum.SOLDOUT && (
              <ButtonMain
                className="text-uppercase col-auto align-self-start px-3 d-flex d-md-block justify-content-center"
                href={props.eventURL}
                as={'a'}
                label="Sold Out"
                variant="primary"
                disabled
              />
            )}
            {props.eventState === EventStateEnum.ONSALE &&
              props.registrationURL !== undefined &&
              props.registrationURL != null &&
              props.registrationURL != '' &&
              !props.isWaitList && (
                <>
                  <PurchaseButton
                    className="text-uppercase col-auto align-self-start px-3 d-none d-md-block justify-content-center"
                    href={props.registrationURL}
                    analyticsData={{
                      event: dataEventPurchaseNow,
                      attributes: {
                        tile_action: dataTileAction
                      }
                    }}
                    aria-label={`Purchase Now. Click here to purchase tickets for ${props.title}`}
                    as={'button'}
                    variant="primary"
                  />
                  <ButtonMain
                    className="text-uppercase col-auto align-self-start px-3 ms-3 me-2 d-block d-md-none"
                    href={`tel:+${props.phoneNumber}`}
                    analyticsData={{
                      event: dataEvent,
                      attributes: {
                        location: dataLocation
                      }
                    }}
                    as={'a'}
                    label={props.phoneNumber}
                    variant="primary"
                  />
                </>
              )}
          </Col>
          <Col lg="auto" xs={12} className="py-1">
            {props.eventState != EventStateEnum.COMINGSOON && (
              <ButtonMain
                className="text-uppercase col-auto align-self-start px-3 d-flex d-md-block justify-content-center"
                href={props.eventURL}
                as={'a'}
                label="Learn More"
                analyticsData={{
                  event: dataEvent,
                  attributes: {
                    location: dataLocation
                  }
                }}
                variant="outline-primary"
              />
            )}
          </Col>
          <Col lg="auto" xs={12} className="py-1">
            {/* Call to purchase */}
            {props.eventState === EventStateEnum.ONSALE &&
              props.phoneNumber !== undefined &&
              props.phoneNumber !== '' &&
              !props.hideCallToPurchase && (
                <>
                  <p className="d-none d-md-block mb-0 mt-3 mt-lg-0 text-uppercase small">
                    Or call to purchase
                  </p>
                  <p className="d-none d-md-block mb-0 mt-3 mt-lg-0 text-uppercase small">
                    <a
                      href={`tel:+${props.phoneNumber}`}
                      onClick={() => {
                        pushDataLayer({
                          event: dataEvent,
                          attributes: {
                            location: dataLocation
                          }
                        });
                      }}>
                      {props.phoneNumber}
                    </a>
                  </p>
                </>
              )}
          </Col>
          <Col lg="auto" xs={12}>
            {/* Terms and Conditions Modal */}
            <Button
              variant=""
              bsPrefix="btn-link"
              className="p-0 text-ia-blue-link font-neue-plak-med bg-transparent border-0 mt-2 mt-md-0"
              onClick={() => {
                setShowOfferTermsModal(true);
                pushDataLayer({
                  event: dataEvent,
                  attributes: {
                    location: dataLocation
                  }
                });
              }}
              aria-label="Offer Terms and Conditions opens in a dialog">
              <small>Offer Terms &amp; Conditions</small>
            </Button>
          </Col>
          <Col md="auto" xs={12} className="ms-md-auto me-md-4 mt-4 mt-md-0 my-0 my-md-3">
            <FacebookShare className="pe-2 me-2 border-end hover-fade" eventURL={props.eventURL} />
            <TwitterShare
              className="pe-2 hover-fade"
              eventURL={props.eventURL}
              title={props.title}
            />
            {props.eventState !== EventStateEnum.COMINGSOON && (
              <CalendarDownload
                className="hover-fade"
                eventURL={props.eventURL}
                eventState={props.eventState}
              />
            )}
          </Col>
        </Row>
      </Col>
      {/* Single event modals */}
      <ContentModal
        header="Offer Terms &amp; Conditions"
        body={props.offerTermsAndConditions}
        show={showOfferTermsModal}
        onHide={() => setShowOfferTermsModal(false)}
      />
    </>
  );
};

export default SeriesTile;

SeriesTile.propTypes = {
  title: PropTypes.string.isRequired,
  eventDateText: PropTypes.string.isRequired,
  locationText: PropTypes.string.isRequired,
  priceString: PropTypes.string.isRequired,
  registrationURL: PropTypes.string,
  eventState: PropTypes.number.isRequired,
  eventURL: PropTypes.string.isRequired,
  offerTermsAndConditions: PropTypes.string,
  phoneNumber: PropTypes.string,
  hideCallToPurchase: PropTypes.bool,
  isWaitList: PropTypes.bool
};
