import { PaymentTypeEnum, EventStateEnum } from './../enums/enums';

function eventStateEnumToClass(val) {
  switch (val) {
    case EventStateEnum.COMINGSOON:
      return 'coming-soon-event';
    case EventStateEnum.UPCOMING:
      return 'upcoming-event';
    case EventStateEnum.PRESALE:
      return 'presale-event';
    case EventStateEnum.POSTPRESALE:
      return 'post-presale-event';
    case EventStateEnum.ONSALE:
      return 'onsale-event';
    case EventStateEnum.SOLDOUT:
      return 'sold-out-event';
    case EventStateEnum.CANCELLED:
      return 'cancelled-event';
    case EventStateEnum.POSTPONED:
      return 'postponed-event';
    case EventStateEnum.SWEEPSTAKESUPCOMING:
      return 'sweepstakes-upcoming-event';
    case EventStateEnum.SWEEPSTAKESOPEN:
      return 'sweepstakes-open-event';
    case EventStateEnum.SWEEPSTAKESCLOSED:
      return 'sweepstakes-closed-event';
    case EventStateEnum.INACTIVE:
    case EventStateEnum.PAST:
    case EventStateEnum.BLANK:
    default:
      return 'past-event';
  }
}

function eventStateEnumToStatusText(val, paymentType, onSaleDateText, sweepstakesStartDate) {
  switch (val) {
    case EventStateEnum.CANCELLED:
      return 'Cancelled';
    case EventStateEnum.POSTPONED:
      return 'Postponed';
    case EventStateEnum.BLANK:
      return '';
    case EventStateEnum.COMINGSOON:
      return 'Coming Soon';
    case EventStateEnum.SOLDOUT:
      return 'Sold Out';
    case EventStateEnum.SWEEPSTAKESUPCOMING:
      return `Enter On: ${sweepstakesStartDate}`;
    case EventStateEnum.SWEEPSTAKESOPEN:
      return 'Enter Now';
    case EventStateEnum.SWEEPSTAKESCLOSED:
      return 'Entries Closed';
    case EventStateEnum.PRESALE:
    case EventStateEnum.POSTPRESALE:
    case EventStateEnum.UPCOMING:
      return `On Sale: ${onSaleDateText}`;
    case EventStateEnum.PAST:
      return 'Past Event';
    case EventStateEnum.ONSALE:
    default:
      if (paymentType == PaymentTypeEnum.BID_MILES) {
        return 'BID NOW';
      } else if (paymentType == PaymentTypeEnum.PAY_MILES) {
        return 'Redeem';
      } else {
        return 'Buy Now';
      }
  }
}

function eventStateEnumToStatusTextForDetailPage(
  val,
  paymentType,
  onSaleDate,
  onSaleTime,
  sweepstakesStartDate
) {
  if (val === EventStateEnum.ONSALE) {
    return 'On Sale Now';
  } else if (
    val === EventStateEnum.UPCOMING ||
    val === EventStateEnum.PRESALE ||
    val === EventStateEnum.POSTPRESALE ||
    val === EventStateEnum.SWEEPSTAKESUPCOMING
  ) {
    return (
      <>
        <span>On Sale: {onSaleDate}</span>
        <br />
        <span>Purchase here at {onSaleTime}</span>
      </>
    );
  }
  return eventStateEnumToStatusText(val, paymentType, onSaleDate, onSaleTime, sweepstakesStartDate);
}

function eventStateStatusToStringAnalytics(val) {
  switch (val) {
    case EventStateEnum.CANCELLED:
      return 'Cancelled';
    case EventStateEnum.POSTPONED:
      return 'Postponed';
    case EventStateEnum.BLANK:
      return 'Blank';
    case EventStateEnum.COMINGSOON:
      return 'Coming Soon';
    case EventStateEnum.SOLDOUT:
      return 'Sold Out';
    case EventStateEnum.SWEEPSTAKESUPCOMING:
      return 'Upcoming Sweepstakes';
    case EventStateEnum.SWEEPSTAKESOPEN:
      return 'Open Sweepstakes';
    case EventStateEnum.SWEEPSTAKESCLOSED:
      return 'Sweepstakes Closed';
    case EventStateEnum.PRESALE:
      return 'Presale';
    case EventStateEnum.POSTPRESALE:
      return 'Post Presale';
    case EventStateEnum.UPCOMING:
      return 'Upcoming Event';
    case EventStateEnum.PAST:
      return 'Past Event';
    case EventStateEnum.ONSALE:
    default:
      return 'On Sale';
  }
}

function paymentEnumToStringAnalytics(val) {
  switch (val) {
    case PaymentTypeEnum.BID_MILES:
      return 'Bid with Miles';
    case PaymentTypeEnum.PAY_MILES:
      return 'Pay with Miles';
    case PaymentTypeEnum.SWEEPSTAKES:
      return 'SweepStakes';
    case PaymentTypeEnum.PAY_CARD:
    default:
      return 'Pay with Card';
  }
}

export {
  eventStateEnumToClass,
  eventStateEnumToStatusText,
  eventStateEnumToStatusTextForDetailPage,
  eventStateStatusToStringAnalytics,
  paymentEnumToStringAnalytics
};
