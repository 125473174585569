import PropTypes from 'prop-types';

const AsideFacet = (props) => {
  return (
    <li className="d-flex mb-3">
      <div className="row">
        <div className="col-auto">{props.icon}</div>
        <div className="col">
          <h3 className="h5"> {props.title}</h3>
          <p>
            {props.body !== undefined && (
              <small
                className="d-flex pt-2"
                dangerouslySetInnerHTML={{ __html: props.body }}></small>
            )}

            {props.keyValueBody !== undefined &&
              props.keyValueBody.map((p, idx) => (
                <small key={idx} className="d-block">
                  {p.key} - {p.value}
                </small>
              ))}
            {props.subtext !== undefined && props.subtext}
          </p>
        </div>
      </div>
    </li>
  );
};

export default AsideFacet;

AsideFacet.propTypes = {
  keyValueBody: PropTypes.array,
  body: PropTypes.string,
  subtext: PropTypes.element,
  packages: PropTypes.array,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired
};
