import PropTypes from 'prop-types';
import React from 'react';
import ButtonMain from './../ButtonMain/ButtonMain';
import { Card, Col } from 'react-bootstrap';

const NullTile = (props) => {
  return (
    <>
      <div className="col" style={{ minHeight: 400 }}>
        <Card className="h-100 text-decoration-none shadow" title="">
          <Card.Body className="d-flex flex-row p-0">
            {props.isImagePresent && (
              <Col
                style={{
                  backgroundImage: `url(${props.imgSrc})`,
                  backgroundSize: 'cover'
                }}></Col>
            )}
            <Col className="d-flex justify-content-center text-center p-3">
              <div className="align-self-center">
                <h2 className="font-neue-plak-med h5 mb-3">
                  Exceptional Events. Amazing Experiences.
                </h2>
                <p>
                  <small>Learn more about past United Card Events from Chase.</small>
                </p>
                <ButtonMain
                  as="a"
                  label="View Past Experiences"
                  aria-label="View Past Experiences of United Card Events"
                  href="/past-events"
                  variant="primary"
                  analyticsData={{
                    event: 'navigationClick',
                    attributes: {
                      location: 'body'
                    }
                  }}
                />
              </div>
            </Col>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

NullTile.propTypes = {
  isImagePresent: PropTypes.bool.isRequired,
  imgSrc: PropTypes.string
};

export default NullTile;
