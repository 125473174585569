import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { OffsiteLink } from './../Icons/Icons';
import { LinkContainer } from 'react-router-bootstrap';
import pushDataLayer from '../../utilities/analytics';

export const ButtonMain = (props) => {
  const [showOffsiteModal, setShowOffsiteModal] = useState(false);
  const buttonClick = (e) => {
    if (props.analyticsData) {
      pushDataLayer(props.analyticsData);
    }
    if (props.onClick) {
      props.onClick(e);
    }
    if (!props.isOffsite) {
      return;
    }

    e.preventDefault();
    setShowOffsiteModal(true);
  };

  const { isOffsite, isChaseOffsite, ...cleanProps } = props;

  const innerButton = (
    <Button {...cleanProps} onClick={(e) => buttonClick(e)}>
      <>
        {props.icon && <span className="ps-0 pe-2">{props.icon}</span>}
        <span>{props.label}</span>
      </>
      {/* if offsite is true, button element needs .position-relative */}
      {isOffsite && <OffsiteLink className="position-absolute end-0 pe-2 pe-sm-3" />}
    </Button>
  );

  return (
    <>
      {isOffsite || isChaseOffsite || props.href == '' || props.href == undefined ? (
        innerButton
      ) : (
        <LinkContainer to={props.href}>{innerButton}</LinkContainer>
      )}

      <Modal
        size="lg"
        show={showOffsiteModal}
        aria-labelledby="leaving-chase"
        onHide={() => setShowOffsiteModal(false)}>
        <Modal.Header className="align-items-start" closeButton>
          <Modal.Title>
            <h1 id="leaving-chase" className="h2 text-primary">
              You&apos;re now leaving Chase
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <p>
            Chase&apos;s website and/or mobile terms, privacy and security policies don&apos;t apply
            to the site or app you&apos;re about to visit. Please review its terms, privacy and
            security policies to see how they apply to you. Chase isn&apos;t responsible for (and
            doesn&apos;t provide) any products, services or content at this third-party site or app,
            except for products and services that explicitly carry the Chase name.
          </p>
          <Button
            as="a"
            variant="primary"
            className="w-100"
            href={props.href}
            target="_blank"
            aria-label={`Continue to navigate offsite to ${props.href}`}>
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

ButtonMain.propTypes = {
  href: PropTypes.string,
  isChaseOffsite: PropTypes.bool,
  isOffsite: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  analyticsData: PropTypes.object,
  icon: PropTypes.element
};

export default ButtonMain;
