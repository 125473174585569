import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

// Remove spaces and replace ampersands with "And"
function transformString(inputString) {
  return inputString.replace(/\s/g, '').replace(/&/g, 'And');
}

const FilterCheck = (props) => {
  return (
    <Form.Check
      label={props.label}
      type={'checkbox'}
      checked={props.isChecked}
      id={'label' + transformString(props.label)}
      onChange={() => {
        props.updateFilter(props.filter, props.label, props.idx);
      }}
    />
  );
};

FilterCheck.propTypes = {
  filter: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  updateFilter: PropTypes.func.isRequired
};

export default FilterCheck;
