import PropTypes from 'prop-types';
import Twitter from './../../assets/icons/icon-twitter.png';
import pushDataLayer from '../../utilities/analytics';

const TwitterShare = (props) => {
  const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    'United Card Events from Chase offers access to once-in-a-lifetime experiences exclusively for select Chase Cardmembers.'
  )}&url=${encodeURIComponent(
    `https://${window.document.location.host}${props.eventURL}`
  )}&hashtags=${encodeURIComponent('UnitedCardEventsfromChase')}`;

  return (
    <a
      className={`btn btn-link p-0 ${props.className}`}
      onClick={(e) => {
        e.preventDefault();
        pushDataLayer({
          event: 'socialMediaClick',
          attributes: {
            social_click: 'Twitter'
          }
        });
        window.open(twitterURL, '_blank');
      }}
      target="_blank"
      href={twitterURL}
      rel="noreferrer"
      aria-label="Share this event on X formerly known as Twitter: Opens in a new page or window.">
      <img style={{ width: 25 }} src={Twitter} alt="X logo, formerly Twitter" />
    </a>
  );
};

export default TwitterShare;

TwitterShare.propTypes = {
  className: PropTypes.string,
  eventURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
