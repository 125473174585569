import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { Home } from './../../components/Icons/Icons';
import { EventStateEnum } from './../../enums/enums';
import { Breadcrumb, Button } from 'react-bootstrap';
import pushDataLayer from '../../utilities/analytics';

export const Breadcrumbs = (props) => {
  const [breadCrumbUrl, setBreadCrumbUrl] = React.useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    setBreadCrumbUrl(window.location.href);
  }, []);

  return (
    <>
      <Breadcrumb
        label="breadcrumb"
        className="my-4 small font-neue-plak-med d-none d-md-flex"
        onClick={() =>
          pushDataLayer({
            event: 'Breadcrumbs',
            attributes: {
              location: 'BreadCrumbs',
              current_url: breadCrumbUrl
            }
          })
        }>
        <LinkContainer to="/">
          <Breadcrumb.Item>
            <span className="me-2">
              <Home />
            </span>
            Home
          </Breadcrumb.Item>
        </LinkContainer>
        {props.eventState === EventStateEnum.PAST && (
          <LinkContainer to="/past-events">
            <Breadcrumb.Item>Past Experiences</Breadcrumb.Item>
          </LinkContainer>
        )}
        {props.eventState !== EventStateEnum.PAST &&
          props.seriesParentName != undefined &&
          props.seriesParentName != '' && (
            <LinkContainer to={props.seriesParentURL}>
              <Breadcrumb.Item>{props.seriesParentName}</Breadcrumb.Item>
            </LinkContainer>
          )}
        {!props.isPhotoGallery && <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>}
        {props.isPhotoGallery && (
          <>
            <LinkContainer to={props.eventURL}>
              <Breadcrumb.Item>{props.title}</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>Photo Gallery</Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>
      <Button
        variant="link"
        as="a"
        className="d-flex d-md-none p-0 text-decoration-none text-black"
        aria-label="Return to event listing page"
        onClick={() => navigate(-1)}>
        &lt; Back
      </Button>
    </>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  title: PropTypes.string.isRequired,
  eventState: PropTypes.number.isRequired,
  eventURL: PropTypes.string.isRequired,
  isPhotoGallery: PropTypes.bool,
  seriesParentName: PropTypes.string,
  seriesParentURL: PropTypes.string
};
