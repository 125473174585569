import PropTypes from 'prop-types';
import Facebook from './../../assets/icons/icon-facebook.png';
import pushDataLayer from '../../utilities/analytics';

const FacebookShare = (props) => {
  const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `https://${window.document.location.host}${props.eventURL}`
  )};&src=sdkpreparse`;

  return (
    <a
      className={`btn btn-link p-0 ${props.className}`}
      onClick={(e) => {
        e.preventDefault();
        pushDataLayer({
          event: 'socialMediaClick',
          attributes: {
            social_click: 'Facebook'
          }
        });
        window.open(facebookURL, '_blank');
      }}
      target="_blank"
      rel="noreferrer"
      href={facebookURL}
      aria-label="Share this event on Facebook: Opens in a new page or window.">
      <img style={{ width: 25 }} src={Facebook} alt="Facebook Icon" />
    </a>
  );
};

export default FacebookShare;

FacebookShare.propTypes = {
  className: PropTypes.string,
  eventURL: PropTypes.string.isRequired
};
