import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const EventLocation = (props) => {
  const locations = [];

  if (props.locations !== undefined) {
    for (let i = 0; i < props.locations.length; ++i) {
      const location = props.locations[i];
      const city =
        location.city != undefined &&
        location.city != null &&
        location.city != '' &&
        location.state != undefined &&
        location.state != null &&
        location.state != ''
          ? `${location.city.trim()}, `
          : '';

      const cityStateString = `${city}${location.state} ${location.zip}`;

      if (location.isVirtual) {
        locations.push(
          <div
            className="my-3"
            dangerouslySetInnerHTML={{ __html: location.virtualCopy }}
            key={`location-${i}`}></div>
        );
      } else {
        locations.push(
          <Row className="my-md-3 my-0" key={`location-${i}`}>
            <Col md={12}>
              <iframe
                title={`Google Maps embed for ${location.venueName}`}
                style={{ border: 0, width: '100%', height: '250px' }}
                src={`https://www.google.com/maps/embed/v1/place?key=${window.GOOGLE_MAPS_KEY}&q=${location.googleMapsString}`}
                allowFullScreen=""></iframe>
            </Col>
            <Col md={12} className="mt-3 mt-md-0 pb-3 pt-1">
              <p className="m-0">
                <strong>{location.venueName}</strong>
                <br />
                {location.address !== undefined && location.address !== '' && (
                  <>
                    {location.address}
                    <br />
                  </>
                )}
                {location.address2 !== undefined && location.address2 !== '' && (
                  <>
                    {location.address2}
                    <br />
                  </>
                )}
                {cityStateString}
                <br />
                {location.venuePhoneNumber}
              </p>
              {location.venueURL !== undefined &&
                location.venueURL != null &&
                location.venueURL != '' && (
                  <p className="m-0">
                    <a href={location.venueURL} target="_blank" rel="noreferrer">
                      {location.venueURL}
                    </a>
                  </p>
                )}
              {location.disclaimer !== undefined &&
                location.disclaimer != null &&
                location.disclaimer != '' &&
                typeof location.disclaimer != 'object' && (
                  <p className="m-0">
                    <small dangerouslySetInnerHTML={{ __html: location.disclaimer }}></small>
                  </p>
                )}
            </Col>
          </Row>
        );
      }
    }
  }

  return (
    <div className="mt-5">
      <h2 className="border-bottom border-grey pb-1 mb-3 h3">Event Location</h2>
      {locations}
    </div>
  );
};

export default EventLocation;

EventLocation.propTypes = {
  locations: PropTypes.array.isRequired
};
