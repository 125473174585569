import PropTypes from 'prop-types';

const Schedule = (props) =>
  props.schedule !== undefined &&
  props.schedule != '' && (
    <div className="mt-5">
      <div className="border-bottom border-grey py-2">
        <h2 className="h3">Tentative Event Schedule</h2>
        {props.scheduleHeaderAdditionalText !== undefined &&
          props.scheduleHeaderAdditionalText != '' && (
            <span dangerouslySetInnerHTML={{ __html: props.scheduleHeaderAdditionalText }}></span>
          )}
      </div>
      <span dangerouslySetInnerHTML={{ __html: props.schedule }}></span>
      {/* Disclaimer text */}
      {props.scheduleDisclaimer !== undefined && props.scheduleDisclaimer != '' && (
        <p>
          <small>{props.scheduleDisclaimer}</small>
        </p>
      )}
    </div>
  );

export default Schedule;

Schedule.propTypes = {
  schedule: PropTypes.string.isRequired,
  scheduleDisclaimer: PropTypes.string,
  scheduleHeaderAdditionalText: PropTypes.string
};
