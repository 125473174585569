import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getGlobalContent } from './../utilities/api';

export const GlobalContext = React.createContext({
  api: {
    regions: [],
    categories: [],
    paymentTypes: [],
    homepage: {},
    evergreenTiles: [],
    aboutModal: {},
    contactModal: {},
    faqModal: {},
    termsModal: {}
  },
  pastEvents: {
    shownEvents: 12,
    addEvents: () => { },
    resetEvents: () => { }
  }
});

export const GlobalContextProvider = (props) => {

  //load more
  const tilesShownConstant = 12;
  const [globalContent, setGlobalContent] = useState({});
  const [count, setCount] = useState(tilesShownConstant);

  useEffect(() => {
    getGlobalContent()
      .then((res) => res.json())
      .then((result) => {
        const months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ];

        const years = [];
        for (let i = 2011; i <= new Date().getFullYear(); ++i) {
          years.push(i.toString());
        }

        result.months = months;
        result.years = years;
        setGlobalContent(result);
      });
  }, []);

  const updateCount = () => {
    setCount(count + tilesShownConstant);
  }
  const resetCount = () => {
    setCount(tilesShownConstant);
  }

  return <GlobalContext.Provider value={{
    api: globalContent,
    pastEvents: { shownEvents: count, addEvents: updateCount, resetEvents: resetCount },
  }}>{props.children}</GlobalContext.Provider>;
};

GlobalContextProvider.propTypes = {
  children: PropTypes.object
};
