import React, { useContext, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../contexts/globalContext';
import { NavigationHighlightContext } from '../../contexts/navigationContext';
import ContentModal from '../../components/ContentModal/ContentModal';
import { Container, Button, Collapse, Col, Row, Nav } from 'react-bootstrap';
import { NagivationHighlightEnum } from '../../enums/enums';
import { UCELogo } from '../../components/Logos/Logos';
import pushDataLayer from '../../utilities/analytics';

const Header = () => {
  const [open, setOpen] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);

  const globalData = useContext(GlobalContext);
  const navigationHighlightState = useContext(NavigationHighlightContext);

  const highlightUpcoming = navigationHighlightState === NagivationHighlightEnum.UPCOMING;
  const highlightPast = navigationHighlightState === NagivationHighlightEnum.PAST;

  const dataHeader = {
    event: 'navigationClick',
    attributes: {
      location: 'header'
    }
  };

  return (
    <>
      <Container as={'header'} fluid className="p-y-0 px-4 bg-white">
        <Container>
          <Row>
            <Col xs={6} className="d-flex">
              <Row className="p-3">
                <UCELogo />
              </Row>
            </Col>
            <Col xs={6} className="d-flex align-content-center flex-wrap">
              <Container className="text-end px-0">
                <Row>
                  <div className="d-none d-md-block py-2">
                    <Button
                      as="a"
                      variant="beige"
                      className="rounded-0 my-3"
                      href="https://www.theexplorercard.com/rewards-cards?cell=ZMR"
                      onClick={() => pushDataLayer(dataHeader)}
                      aria-label="Not a Cardmember? Click to navigate to the Explorer Card in a new tab">
                      Not a Cardmember?
                    </Button>
                    <Nav className="d-none d-md-flex justify-content-end pb-2">
                      <LinkContainer to="/">
                        <Nav.Link
                          className={`nav-link-hover-decoration px-0 me-3 ${
                            highlightUpcoming
                              ? 'text-purple border-bottom border-purple hover-no-border'
                              : ''
                          }`}
                          aria-current={highlightUpcoming ? 'page' : undefined}
                          onClick={() => pushDataLayer(dataHeader)}>
                          Upcoming Experiences
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/past-events">
                        <Nav.Link
                          className={`nav-link-hover-decoration px-0 ${
                            highlightPast
                              ? 'text-purple border-bottom border-purple hover-no-border'
                              : ''
                          }`}
                          aria-current={highlightPast ? 'page' : undefined}
                          onClick={() => pushDataLayer(dataHeader)}>
                          Past Experiences
                        </Nav.Link>
                      </LinkContainer>
                      <Nav.Link
                        href="#"
                        className="nav-link-hover-decoration px-0 ms-3"
                        aria-label="Opens modal to learn more about United Card Events from Chase"
                        onClick={() => {
                          setShowAboutModal(true);
                          pushDataLayer(dataHeader);
                        }}>
                        About
                      </Nav.Link>
                    </Nav>
                  </div>
                </Row>
                {/* Hamburger Trigger for Mobile */}
                <Button
                  bsPrefix="btn-link"
                  className="d-inline-flex d-md-none border-0 hamburger-trigger bg-white"
                  onClick={() => {
                    setOpen(!open);
                    pushDataLayer(dataHeader);
                  }}
                  aria-controls="menu-toggle"
                  aria-label="navigation menu"
                  aria-expanded={open}>
                  <span className="hamburger p-3 d-block">
                    <span className="bar1 d-block"></span>
                    <span className="bar2 d-block"></span>
                    <span className="bar3 d-block"></span>
                  </span>
                </Button>
                {/* End Hamburger Trigger for Mobile */}
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Mobile Accordion (Collapse) triggered above */}
      <div className="d-flex d-md-none">
        <Collapse in={open} className="container-fluid">
          <div className="row" id="menu-toggle">
            <Col>
              <Nav className="d-flex flex-column py-3">
                <LinkContainer to="/">
                  <Nav.Link
                    className={`py-2  ${highlightUpcoming ? 'text-purple' : 'text-primary'}`}
                    onClick={() => pushDataLayer(dataHeader)}>
                    Upcoming Experiences
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/past-events">
                  <Nav.Link
                    className={`py-2 ${highlightPast ? 'text-purple' : 'text-primary'}`}
                    onClick={() => pushDataLayer(dataHeader)}>
                    Past Experiences
                  </Nav.Link>
                </LinkContainer>
                <Nav.Link
                  className="py-2 text-primary"
                  aria-label="Opens modal to learn more about United Card Events from Chase"
                  onClick={() => {
                    setShowAboutModal(true);
                    pushDataLayer(dataHeader);
                  }}>
                  About
                </Nav.Link>
                <hr className="bg-primary my-2" aria-hidden="true" />
                <Nav.Link
                  href="https://www.theexplorercard.com/rewards-cards?cell=ZMR"
                  className="text-primary py-2"
                  aria-label="Not a Cardmember? Click to navigate to the Explorer Card in a new tab"
                  onClick={() => pushDataLayer(dataHeader)}>
                  Not a cardmember?
                </Nav.Link>
              </Nav>
            </Col>
          </div>
        </Collapse>
      </div>
      {/* End mobile accordion */}
      {globalData.api.aboutModal !== undefined && (
        <ContentModal
          header={globalData.api.aboutModal.title}
          body={globalData.api.aboutModal.body}
          show={showAboutModal}
          onHide={() => setShowAboutModal(false)}
        />
      )}
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
