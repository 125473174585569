import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const ThumbnailImage = (props) => {
  return (
    <Button className="p-2" variant="link" onClick={() => props.onClick()}>
      <img
        src={`${props.src}`}
        data-full-size={`${props.src}`}
        data-image-id={props.src.split('.')[0]}
        alt={props.alt === undefined || props.alt == '' ? props.ariaLabel : props.alt}
        className="lazyload"
      />
    </Button>
  );
};

export default ThumbnailImage;

ThumbnailImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired
};
