import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';

const CarouselContent = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentCaption, setCurrentCaption] = useState('');
  const [currentAltText, setCurrentAltText] = useState('');
  const items = [];

  const carouselImages = props.featuredImages;
  const handleSlide = (eventKey) => {
    setCurrentSlide(eventKey);
  };

  // If carousel has 1 image, do not set aria-label.  If carousel has 2+ images, set aria-label
  const sectionAriaLabel =
    carouselImages !== undefined && carouselImages.length === 1 ? false : true;

  // caption and alt text is updated every time current slide is updated
  useEffect(() => {
    if (carouselImages !== undefined) {
      const altText = carouselImages.map((img) => {
        return img.altText
          ? img.altText
          : `Carousel Image ${currentSlide + 1} of ${carouselImages.length}`;
      });

      const captionText = carouselImages[currentSlide].caption
        ? carouselImages[currentSlide].caption
        : '';
      setCurrentCaption(captionText);
      setCurrentAltText(altText);
    }
  }, [carouselImages, currentSlide, props]);

  if (carouselImages !== undefined) {
    const featuredImages = carouselImages.length > 6 ? carouselImages.slice(0, 6) : carouselImages;

    featuredImages.map((value) => {
      return items.push(
        <Carousel.Item key={value.imageURL}>
          <img
            src={`${value.imageURL}`}
            alt={carouselImages[currentSlide].altText}
            className="d-block w-100"
          />
        </Carousel.Item>
      );
    });
  }

  return (
    <section
      {...(sectionAriaLabel
        ? { 'aria-label': `Image Carousel contains ${carouselImages.length} slides` }
        : {})}
      role="region">
      <Carousel
        keyboard={true}
        interval={null}
        touch={true}
        indicators={items.length > 1}
        indicatorLabels={currentAltText}
        controls={items.length > 1}
        onSlide={handleSlide}
        aria-live="assertive"
        className="mt-md-4 mb-5">
        {items}
      </Carousel>
      <div
        className={`row pb-3 ${
          currentCaption == undefined || currentCaption == '' ? '' : 'mt-n5'
        }`}>
        <p className="text-center text-dark-grey2">
          <em>{currentCaption}</em>
        </p>
      </div>
    </section>
  );
};

export default CarouselContent;

CarouselContent.propTypes = {
  featuredImages: PropTypes.array.isRequired
};
