import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

import pushDataLayer from '../../utilities/analytics';

export const PhoneButton = (props) => {
    const buttonClick = (e) => {
        if (props.analyticsData) {
            pushDataLayer(props.analyticsData);
        }
        if (props.onClick) {
            props.onClick(e);
        }
    };

    const { ...cleanProps } = props;

    return (
        <>
            <Button {...cleanProps} onClick={(e) => buttonClick(e)}>
                <span>{props.label}</span>
            </Button>
        </>
    );
};

PhoneButton.propTypes = {
    href: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    analyticsData: PropTypes.object
};

export default PhoneButton;
