const getOnSaleCalendarLink = (eventSlug) =>
  `${window.API_URL}calendar?slug=${eventSlug}&calendarType=onsale`;

const getEventDateCalendarLink = (eventSlug) =>
  `${window.API_URL}calendar?slug=${eventSlug}&calendarType=event`;

const getSweepstakesEntryCalendarLink = (eventSlug) =>
  `${window.API_URL}calendar?slug=${eventSlug}&calendarType=sweepstakesupcoming`;

export { getOnSaleCalendarLink, getEventDateCalendarLink, getSweepstakesEntryCalendarLink };
