import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Card } from 'react-bootstrap';
import { CCard, Paddle, Plane, Ticket } from './../Icons/Icons';
import VisaLogo from './../../assets/logos/visa.png';
import VisaSignatureLogo from './../../assets/logos/visasignature.png';
import { EventStateEnum, EventTypeEnum, PaymentTypeEnum } from './../../enums/enums';
import {
  eventStateEnumToStatusText,
  eventStateStatusToStringAnalytics,
  paymentEnumToStringAnalytics
} from './../../utilities/enumTranslation';
import pushDataLayer from '../../utilities/analytics';

const EventTile = (props) => {
  const getEventStateClass = (eventState) => {
    switch (eventState) {
      case EventStateEnum.BLANK:
      case EventStateEnum.PAST:
        return '';
      case EventStateEnum.ONSALE:
      case EventStateEnum.SWEEPSTAKESOPEN:
        return 'bg-yellow';
      case EventStateEnum.UPCOMING:
        return 'bg-light-blue';
      case EventStateEnum.PRESALE:
      case EventStateEnum.POSTPRESALE:
        return 'bg-lavendar';
      case EventStateEnum.SOLDOUT:
      case EventStateEnum.CANCELLED:
      case EventStateEnum.POSTPONED:
      case EventStateEnum.SWEEPSTAKESCLOSED:
        return 'bg-banner-dkgrey';
      case EventStateEnum.SWEEPSTAKESUPCOMING:
        return 'bg-banner-grey';
      default:
        return 'bg-banner-grey';
    }
  };

  //CUCE-334

  const storeLinkForScroll = (eventUrl) => {
    localStorage.setItem('eventBackCache', eventUrl);
  };

  const scrollRef = useRef(null);
  const cachedEvent = localStorage.getItem('eventBackCache')?.includes(props.eventURL)
    ? scrollRef
    : null;

  useEffect(() => {
    scrollRef?.current?.focus();
    //clear storage so normal page navigation doesn't trigger scrolling
    localStorage.clear();
  }, []);

  const Tile = (
    <Card
      as="a"
      className="animate-trigger h-100 text-decoration-none overflow-hidden shadow border-0 text-black d-flex flex-column-reverse"
      href={props.eventURL}
      id={props.id}
      title={props.title}
      ref={cachedEvent}>
      <Card.Body className="flex-column d-flex">
        {/* CTEC-272: reversing non-reversed content for screen readers */}
        <div className="d-flex flex-column-reverse">
          <Card.Title
            className="text-truncate h6 line-height-150 mb-3-md mb-1"
            as={EventStateEnum.PAST ? 'h2' : 'h3'}>
            <small>{props.title}</small>
          </Card.Title>
          <p className="small mb-3-md mb-1">
            <small>{props.locationText}</small>
          </p>
          <p className="small  mb-3-md mb-1">
            <small>{props.eventDateTileString}</small>
          </p>
        </div>

        {props.eventState !== EventStateEnum.PAST && (
          <>
            {props.eventState !== EventStateEnum.COMINGSOON ? (
              <p className="small  mb-3-md mb-1">
                <small>{props.priceString}</small>
              </p>
            ) : (
              <p className="small  mb-3-md mb-1">Check back for details</p>
            )}
            <p className="position-relative mt-auto mb-0 text-black small font-neue-plak-med">
              {props.eventState !== EventStateEnum.COMINGSOON && (
                <>
                  {props.paymentType == PaymentTypeEnum.PAY_CARD && !props.isEventFree && (
                    <>
                      <CCard className="fill-black me-2" width={'20px'} height={'22px'} />
                      <small className="pe-1  mb-3-md mb-1">Pay with your card</small>
                    </>
                  )}
                  {props.paymentType == PaymentTypeEnum.PAY_MILES && !props.isEventFree && (
                    <>
                      <Plane className="fill-black me-2" width={'16px'} height={'22px'} />
                      <small className="pe-1  mb-3-md mb-1">Pay with your miles</small>
                    </>
                  )}
                  {props.paymentType == PaymentTypeEnum.BID_MILES && !props.isEventFree && (
                    <>
                      <Paddle className="fill-black me-2" width={'20px'} height={'22px'} />
                      <small className="pe-1  mb-3-md mb-1">Bid with your miles</small>
                    </>
                  )}
                  {props.paymentType == PaymentTypeEnum.SWEEPSTAKES && !props.isEventFree && (
                    <>
                      <Ticket className="fill-black me-2" width={'20px'} height={'22px'} />
                      <small className="pe-1  mb-3-md mb-1">Enter for a chance to win</small>
                    </>
                  )}
                </>
              )}
            </p>
          </>
        )}
      </Card.Body>
      <div className="overflow-hidden position-relative">
        <img
          src={props.tileImage == undefined ? '' : props.tileImage.imageURL}
          className="animate-zoom w-100"
          alt=""
        />
        {props.isVisaEvent && (
          <div
            className={`position-absolute top-0 w-100 bg-banner-visa-blue`}
            style={{ height: '36px' }}>
            <img className={`position-absolute end-0 p-2 w-auto`} src={VisaLogo} alt="Visa Logo" />
          </div>
        )}
        {props.isVisaSignatureEvent && (
          <div
            className={`position-absolute top-0 w-100 bg-banner-visa-blue`}
            style={{ height: '40px' }}>
            <img
              className={`position-absolute end-0 p-2 w-auto`}
              src={VisaSignatureLogo}
              alt="Visa Logo"
            />
          </div>
        )}
        {props.eventState !== EventStateEnum.PAST && (
          <div
            className={`position-absolute bottom-0 w-100 ${getEventStateClass(props.eventState)}`}
            style={{ opacity: '80%' }}>
            <p className="m-0 font-neue-plak-med text-uppercase text-center py-2 py-md-3">
              {eventStateEnumToStatusText(
                props.eventState,
                props.paymentType,
                props.onSaleDateText,
                props.sweepstakesStartDateText
              )}
            </p>
          </div>
        )}
      </div>
    </Card>
  );

  return (
    <div className="col-lg-3 col-6">
      {props.eventType === EventTypeEnum.MP ? (
        Tile
      ) : (
        <LinkContainer
          to={props.eventURL}
          onClick={(e) => {
            pushDataLayer({
              event: 'travelTileClick',
              attributes: {
                location: 'body',
                event_date: props.onSaleDateText,
                event_location: props.locationText,
                event_description: props.title,
                event_price: props.priceString,
                payment_method: props.isEventFree
                  ? 'Free event'
                  : paymentEnumToStringAnalytics(props.paymentType),
                event_status: eventStateStatusToStringAnalytics(props.eventState),
                event_url: e.currentTarget.href
              }
            });
            storeLinkForScroll(props.eventURL);
          }}>
          {Tile}
        </LinkContainer>
      )}
    </div>
  );
};

EventTile.propTypes = {
  eventDateTileString: PropTypes.string.isRequired,
  eventState: PropTypes.number.isRequired,
  eventURL: PropTypes.string.isRequired,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  tileImage: PropTypes.object.isRequired,
  locationText: PropTypes.string.isRequired,
  priceString: PropTypes.string.isRequired,
  onSaleDateText: PropTypes.string.isRequired,
  sweepstakesStartDateText: PropTypes.string.isRequired,
  paymentType: PropTypes.number.isRequired,
  isVisaSignatureEvent: PropTypes.bool,
  isVisaEvent: PropTypes.bool,
  eventType: PropTypes.number,
  isEventFree: PropTypes.bool
};

export default EventTile;
