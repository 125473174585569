import PropTypes from 'prop-types';
import { React, useState } from 'react';
import { Badge } from 'react-bootstrap';

export const FilterPill = (props) => {
  const [isHover, setIsHover] = useState(false);
  const handleHover = () => {
    setIsHover(true);
  };
  const handleHoverOut = () => {
    setIsHover(false);
  };
  return (
    <Badge
      as="button"
      pill
      bg="light"
      text="dark"
      className="bg-light border-0 mx-1 my-2 hover-ia-blue-link pill-outline"
      data-filter={props.label.replace(/\s/g, '').toLowerCase()}
      aria-label={`Remove ${props.label} from filter`}
      onClick={() => props.updateFilter(props.filter, props.label, props.idx)}
      onMouseOver={handleHover}
      onMouseOut={handleHoverOut}
      onFocus={handleHover}
      onBlur={handleHoverOut}>
      <span className="align-middle">{props.label}</span>
      <span
        className={`${
          isHover ? 'btn-close-white' : 'btn-close-black'
        } btn-close ms-2 px-2 align-middle`}
        style={{ opacity: '1', fontSize: '0.75rem' }}
        aria-hidden="true"></span>
    </Badge>
  );
};

FilterPill.propTypes = {
  filter: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  updateFilter: PropTypes.func.isRequired
};

export default FilterPill;
