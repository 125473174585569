import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
//import useBrowserSize from './../hooks/browser-size';
import Breadcrumbs from './../components/Breadcrumbs/Breadcrumbs';
import EventAside from './../components/EventAside/EventAside';
import EventArticle from './../components/EventArticle/EventArticle';
import { getSingleEventData } from './../utilities/api';
import { WebIdDispatchContext } from '../contexts/navigationContext';
import { NagivationHighlightEnum } from '../enums/enums';
import { NavigationHighlightDispatchContext } from '../contexts/navigationContext';
import { EventStateEnum } from '../enums/enums';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const DetailPage = () => {
  const { series, id } = useParams();
  const [eventData, setEventData] = React.useState(undefined);
  const [show404, setShow404] = React.useState(false);

  const setWebId = useContext(WebIdDispatchContext);
  const setNavigationHighlightState = useContext(NavigationHighlightDispatchContext);
  //const [, screenSize] = useBrowserSize();
  //const isDesktop = ['md', 'lg', 'xl'].includes(screenSize);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);

    getSingleEventData(series !== undefined ? `${series}/${id}` : id)
      .then((res) => {
        if (res.status == 404) {
          return undefined;
        }

        return res.json();
      })
      .then((data) => {
        if (data === undefined) {
          setShow404(true);
          setEventData(undefined);
          return;
        }

        if (data.eventState === EventStateEnum.COMINGSOON) {
          window.location = `https://${window.location.host}/`;
        } else {
          setEventData(data);
          setWebId(data.webId);
          setNavigationHighlightState(
            data.eventState === EventStateEnum.PAST
              ? NagivationHighlightEnum.PAST
              : NagivationHighlightEnum.UPCOMING
          );
        }
      });
  }, [id, series, setNavigationHighlightState, setWebId]);

  return (
    <>
      {eventData !== undefined && (
        <Helmet>
          <title>Event Details | {eventData.title}</title>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@Chase" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={eventData.title} />
          <meta
            property="og:url"
            content={`https://www.unitedcardevents.com${eventData.eventURL}`}
          />
          <meta property="og:image" content={eventData.tileImage.imageURL} />
          <meta property="og:description" content={eventData.metaDescription} />
          <meta property="og:site_name" content="United Card Events from Chase" />
        </Helmet>
      )}

      {show404 && (
        <Container as="main">
          <Row className="m-5">
            <h2 className="text-center">Page not found.</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </Row>
        </Container>
      )}

      {!show404 && (
        <Container as="main">
          {eventData !== undefined && <Breadcrumbs {...eventData} />}
          <a href="#eventDetails" id="skipToEventDetails">
            Skip to Event Details
          </a>
          <Row>
            {eventData !== undefined && <EventAside {...eventData} />}
            {eventData !== undefined && <EventArticle {...eventData} />}
          </Row>
        </Container>
      )}
    </>
  );
};

export default DetailPage;
