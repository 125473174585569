import React from 'react';
import debounce from '../utilities/debounce.js';

function useBrowserSize() {
  const [browserWidth, setBrowserWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setBrowserWidth(window.innerWidth);
    }, 250);
    // Start the listener
    window.addEventListener('resize', debouncedHandleResize);
    // Executes when the component unmounts
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
    // anything added into [] will be listened for and if it changes, will run this "effect" again.
  }, []);
  const result = React.useMemo(() => {
    let size = 'xs';
    if (browserWidth >= 1200) {
      size = 'xl';
    } else if (browserWidth >= 992) {
      size = 'lg';
    } else if (browserWidth >= 768) {
      size = 'md';
    } else if (browserWidth >= 576) {
      size = 'sm';
    }
    return [browserWidth, size]; // [1200, 'md']
  }, [browserWidth]);
  return result;
}
export default useBrowserSize;
