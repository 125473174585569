import PropTypes from 'prop-types';

const ExperienceIncludes = (props) =>
  props.experienceIncludes !== undefined &&
  props.experienceIncludes != '' && (
    <div className="mt-5">
      <h2 className="border-bottom border-grey py-2 h3"> Experience Includes </h2>
      <span dangerouslySetInnerHTML={{ __html: props.experienceIncludes }}></span>
    </div>
  );

export default ExperienceIncludes;

ExperienceIncludes.propTypes = {
  experienceIncludes: PropTypes.string.isRequired
};
