import PropTypes from 'prop-types';
import React from 'react';

export const OffsiteLink = (props) => (
  <>
    <span className="sr-only">
      Legend: when encountering this icon, users will be navigated off of Chase property
    </span>
    <span className={props.className}>
      <svg
        className="logo-default"
        xmlns="http://www.w3.org/2000/svg"
        width="17px"
        height="17px"
        viewBox="0 0 17 17">
        <title>Third-party website disclaimer icon</title>
        <g>
          <circle fill="#C7662C" cx="8.5" cy="8.5" r="7.9" />
          <g>
            <path
              fill="#FFFFFF"
              d="M8.9,11.4c-0.1,0-0.3,0-0.4-0.1c-1.1-0.3-1.9-1.1-2.1-2.2c-0.1-0.5,0-1,0.2-1.5c0,0,0,0,0.1,0
			c0.4,0,0.8,0,1.2,0l0,0.1C7.7,7.9,7.6,8.2,7.6,8.4c0,0.6,0.3,1.2,0.8,1.5c0.3,0.2,0.6,0.3,1,0.3c0.8,0,1.6,0,2.4,0
			c0.8,0,1.5-0.6,1.7-1.4c0.1-0.3,0-0.7-0.1-1c-0.2-0.5-0.7-0.9-1.3-1c-0.4-0.1-0.8-0.1-1.2-0.1c-0.1,0-0.1,0-0.1-0.1
			c-0.2-0.4-0.5-0.8-0.8-1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.5,0,1,0.1,1.5,0.2c0.8,0.3,1.4,0.9,1.6,1.7
			c0.1,0.3,0.1,0.5,0.2,0.8c0,0,0,0.1,0,0.1v0.3c0,0,0,0.1,0,0.1c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.5,0.8
			c-0.5,0.5-1.1,0.7-1.8,0.8c-0.1,0-0.3,0-0.4,0L8.9,11.4z"
            />
            <path
              fill="#FFFFFF"
              d="M6,10.2c0.2,0.4,0.6,0.8,1,1.1c0,0-0.1,0-0.1,0c-0.5,0-1.1,0-1.6,0c-0.3,0-0.5,0-0.8-0.1
			C3.7,11,3.1,10.6,2.8,10c-0.6-0.9-0.6-2-0.1-3c0.4-0.7,1.2-1.3,2-1.4c0.2,0,0.4,0,0.6,0c0.8,0,1.6,0,2.4,0c0.4,0,0.8,0,1.1,0.2
			c0.9,0.3,1.5,1.1,1.7,2c0.1,0.5,0.1,0.9-0.1,1.4c0,0.1,0,0.2-0.1,0.3c0,0,0,0-0.1,0c-0.4,0-0.8,0-1.2,0c0-0.2,0.1-0.3,0.1-0.5
			c0.1-0.4,0.1-0.8,0-1.1C9,7.4,8.6,7,8.1,6.8C7.9,6.8,7.7,6.7,7.5,6.8c-0.8,0-1.7,0-2.5,0c-0.7,0-1.3,0.4-1.5,1
			c-0.2,0.5-0.2,1,0.1,1.4c0.3,0.6,0.9,1,1.6,1C5.4,10.2,5.7,10.2,6,10.2z"
            />
          </g>
        </g>
      </svg>
      <svg
        className="logo-hcontrast"
        xmlns="http://www.w3.org/2000/svg"
        width="17px"
        height="17px"
        viewBox="0 0 17 17">
        <title>Third-party website disclaimer icon</title>
        <g>
          <circle fill="#000000" cx="8.5" cy="8.5" r="7.9" />
          <g>
            <path
              fill="#FFFFFF"
              d="M8.9,11.4c-0.1,0-0.3,0-0.4-0.1c-1.1-0.3-1.9-1.1-2.1-2.2c-0.1-0.5,0-1,0.2-1.5c0,0,0,0,0.1,0
			c0.4,0,0.8,0,1.2,0l0,0.1C7.7,7.9,7.6,8.2,7.6,8.4c0,0.6,0.3,1.2,0.8,1.5c0.3,0.2,0.6,0.3,1,0.3c0.8,0,1.6,0,2.4,0
			c0.8,0,1.5-0.6,1.7-1.4c0.1-0.3,0-0.7-0.1-1c-0.2-0.5-0.7-0.9-1.3-1c-0.4-0.1-0.8-0.1-1.2-0.1c-0.1,0-0.1,0-0.1-0.1
			c-0.2-0.4-0.5-0.8-0.8-1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.5,0,1,0.1,1.5,0.2c0.8,0.3,1.4,0.9,1.6,1.7
			c0.1,0.3,0.1,0.5,0.2,0.8c0,0,0,0.1,0,0.1v0.3c0,0,0,0.1,0,0.1c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.5,0.8
			c-0.5,0.5-1.1,0.7-1.8,0.8c-0.1,0-0.3,0-0.4,0L8.9,11.4z"
            />
            <path
              fill="#FFFFFF"
              d="M6,10.2c0.2,0.4,0.6,0.8,1,1.1c0,0-0.1,0-0.1,0c-0.5,0-1.1,0-1.6,0c-0.3,0-0.5,0-0.8-0.1
			C3.7,11,3.1,10.6,2.8,10c-0.6-0.9-0.6-2-0.1-3c0.4-0.7,1.2-1.3,2-1.4c0.2,0,0.4,0,0.6,0c0.8,0,1.6,0,2.4,0c0.4,0,0.8,0,1.1,0.2
			c0.9,0.3,1.5,1.1,1.7,2c0.1,0.5,0.1,0.9-0.1,1.4c0,0.1,0,0.2-0.1,0.3c0,0,0,0-0.1,0c-0.4,0-0.8,0-1.2,0c0-0.2,0.1-0.3,0.1-0.5
			c0.1-0.4,0.1-0.8,0-1.1C9,7.4,8.6,7,8.1,6.8C7.9,6.8,7.7,6.7,7.5,6.8c-0.8,0-1.7,0-2.5,0c-0.7,0-1.3,0.4-1.5,1
			c-0.2,0.5-0.2,1,0.1,1.4c0.3,0.6,0.9,1,1.6,1C5.4,10.2,5.7,10.2,6,10.2z"
            />
          </g>
        </g>
      </svg>
      <svg
        className="logo-bwcontrast"
        xmlns="http://www.w3.org/2000/svg"
        width="17px"
        height="17px"
        viewBox="0 0 17 17">
        <title>Third-party website disclaimer icon</title>
        <g>
          <circle fill="#FFFFFF" cx="8.5" cy="8.5" r="7.9" />
          <g>
            <path
              fill="#000000"
              d="M8.9,11.4c-0.1,0-0.3,0-0.4-0.1c-1.1-0.3-1.9-1.1-2.1-2.2c-0.1-0.5,0-1,0.2-1.5c0,0,0,0,0.1,0
			c0.4,0,0.8,0,1.2,0l0,0.1C7.7,7.9,7.6,8.2,7.6,8.4c0,0.6,0.3,1.2,0.8,1.5c0.3,0.2,0.6,0.3,1,0.3c0.8,0,1.6,0,2.4,0
			c0.8,0,1.5-0.6,1.7-1.4c0.1-0.3,0-0.7-0.1-1c-0.2-0.5-0.7-0.9-1.3-1c-0.4-0.1-0.8-0.1-1.2-0.1c-0.1,0-0.1,0-0.1-0.1
			c-0.2-0.4-0.5-0.8-0.8-1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.5,0,1,0.1,1.5,0.2c0.8,0.3,1.4,0.9,1.6,1.7
			c0.1,0.3,0.1,0.5,0.2,0.8c0,0,0,0.1,0,0.1v0.3c0,0,0,0.1,0,0.1c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.5,0.8
			c-0.5,0.5-1.1,0.7-1.8,0.8c-0.1,0-0.3,0-0.4,0L8.9,11.4z"
            />
            <path
              fill="#000000"
              d="M6,10.2c0.2,0.4,0.6,0.8,1,1.1c0,0-0.1,0-0.1,0c-0.5,0-1.1,0-1.6,0c-0.3,0-0.5,0-0.8-0.1
			C3.7,11,3.1,10.6,2.8,10c-0.6-0.9-0.6-2-0.1-3c0.4-0.7,1.2-1.3,2-1.4c0.2,0,0.4,0,0.6,0c0.8,0,1.6,0,2.4,0c0.4,0,0.8,0,1.1,0.2
			c0.9,0.3,1.5,1.1,1.7,2c0.1,0.5,0.1,0.9-0.1,1.4c0,0.1,0,0.2-0.1,0.3c0,0,0,0-0.1,0c-0.4,0-0.8,0-1.2,0c0-0.2,0.1-0.3,0.1-0.5
			c0.1-0.4,0.1-0.8,0-1.1C9,7.4,8.6,7,8.1,6.8C7.9,6.8,7.7,6.7,7.5,6.8c-0.8,0-1.7,0-2.5,0c-0.7,0-1.3,0.4-1.5,1
			c-0.2,0.5-0.2,1,0.1,1.4c0.3,0.6,0.9,1,1.6,1C5.4,10.2,5.7,10.2,6,10.2z"
            />
          </g>
        </g>
      </svg>
    </span>
  </>
);

OffsiteLink.propTypes = {
  className: PropTypes.string
};

function Facebook(props) {
  const facebookURL = 'https://www.facebook.com/UnitedCardsfromChase';
  return (
    <a
      className={props.className}
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
        window.open(facebookURL, '_blank');
      }}
      href={facebookURL}
      target="_blank"
      rel="noreferrer"
      aria-label="Visit United Card Events on Facebook (opens in a new window)">
      <svg
        className={props.svgClass}
        xmlns="http://www.w3.org/2000/svg"
        width="37px"
        height="37px"
        viewBox="0 0 48 48"
        aria-hidden="true"
        focusable="false">
        <title>share on facebook icon</title>
        <path d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0Zm6.45,24H26V39H20V24H17V18h3V15.29C20,12.83,21.14,9,26.29,9l4.63,0v5.14H27.56a1.27,1.27,0,0,0-1.33,1.44V18H31Z" />
      </svg>
    </a>
  );
}

Facebook.propTypes = {
  className: PropTypes.string,
  svgClass: PropTypes.string,
  onClick: PropTypes.func
};

function Instagram(props) {
  const instagramURL = 'https://www.instagram.com/unitedcards/';
  return (
    <a
      className={props.className}
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
        window.open(instagramURL, '_blank');
      }}
      href={instagramURL}
      target="_blank"
      rel="noreferrer"
      aria-label="Visit United Card Events on Instagram (opens in a new window)">
      <svg
        className={props.svgClass}
        xmlns="http://www.w3.org/2000/svg"
        width="37px"
        height="37px"
        viewBox="0 0 48 48"
        aria-hidden="true"
        focusable="false">
        <title>share on instagram icon</title>
        <path d="M24,19.21A4.79,4.79,0,1,0,28.79,24,4.79,4.79,0,0,0,24,19.21Z" />
        <path d="M35.19,15.53a4.71,4.71,0,0,0-2.72-2.72,8,8,0,0,0-2.67-.5c-1.51-.07-2-.08-5.8-.08s-4.29,0-5.8.08a8,8,0,0,0-2.67.5,4.52,4.52,0,0,0-1.65,1.07,4.44,4.44,0,0,0-1.07,1.65,8,8,0,0,0-.5,2.67c-.06,1.51-.08,2-.08,5.8s0,4.29.08,5.8a8,8,0,0,0,.5,2.67,4.44,4.44,0,0,0,1.07,1.65,4.52,4.52,0,0,0,1.65,1.07,8,8,0,0,0,2.67.5c1.51.07,2,.08,5.8.08s4.29,0,5.8-.08a8,8,0,0,0,2.67-.5,4.71,4.71,0,0,0,2.72-2.72,8,8,0,0,0,.5-2.67c.07-1.51.08-2,.08-5.8s0-4.29-.08-5.8A8,8,0,0,0,35.19,15.53ZM24,31.37A7.37,7.37,0,1,1,31.37,24,7.37,7.37,0,0,1,24,31.37Zm7.67-13.31a1.73,1.73,0,1,1,1.72-1.72A1.72,1.72,0,0,1,31.67,18.06Z" />
        <path d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM38.27,29.92a10.76,10.76,0,0,1-.66,3.49A7.11,7.11,0,0,1,36,36a7,7,0,0,1-2.54,1.65,10.47,10.47,0,0,1-3.49.67c-1.53.07-2,.09-5.92.09s-4.39,0-5.92-.09a10.35,10.35,0,0,1-3.48-.67A6.9,6.9,0,0,1,12.05,36a7.08,7.08,0,0,1-1.65-2.54,10.47,10.47,0,0,1-.67-3.49c-.07-1.53-.09-2-.09-5.92s0-4.39.09-5.92a10.35,10.35,0,0,1,.67-3.48,7,7,0,0,1,1.65-2.55A6.9,6.9,0,0,1,14.6,10.4a10.35,10.35,0,0,1,3.48-.67c1.53-.07,2-.09,5.92-.09s4.39,0,5.92.09a10.47,10.47,0,0,1,3.49.67A7,7,0,0,1,36,12.05a7.06,7.06,0,0,1,1.66,2.55,10.63,10.63,0,0,1,.66,3.48c.07,1.53.09,2,.09,5.92S38.34,28.39,38.27,29.92Z" />
      </svg>
    </a>
  );
}

Instagram.propTypes = {
  className: PropTypes.string,
  svgClass: PropTypes.string,
  onClick: PropTypes.func
};

function Plane(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="20px"
      viewBox="0 0 41.69 41.7"
      aria-hidden="true"
      focusable="false">
      <path d="M41.69,24.52A9,9,0,0,0,32.77,16H27a.79.79,0,0,1-.78-.42c-.75-1.22-1.54-2.41-2.31-3.61C21.52,8.2,19.13,4.47,16.71.76a1.53,1.53,0,0,0-1-.71C14,0,12.31,0,10.59,0A.89.89,0,0,0,9.7,1.19a4.43,4.43,0,0,0,.18.59l4.38,13.56c.06.18.11.37.18.61-2.44,0-4.81,0-7.18,0a.66.66,0,0,1-.74-.52c-.68-1.8-1.39-3.58-2.08-5.37-.16-.42-.38-.78-.89-.79-.88,0-1.76,0-2.64,0a.85.85,0,0,0-.89,1C0,13.49,0,16.74.05,20a6.12,6.12,0,0,0,6,5.77h8.38c-.11.34-.18.61-.27.88L9.8,40.19c-.33,1,0,1.49,1.08,1.5,1.49,0,3,0,4.47,0a1.42,1.42,0,0,0,1.39-.77q4.71-7.35,9.44-14.68a1,1,0,0,1,.92-.51c4.47,0,8.95,0,13.42,0C41.41,25.75,41.73,25.42,41.69,24.52ZM12,2.09a.93.93,0,0,1,0-.24c1,0,2.07,0,3.1,0a.6.6,0,0,1,.37.26q4.41,6.83,8.8,13.68s0,0,0,.11l-.39,0H16.93c-.28,0-.52,0-.64-.37Q14.13,8.83,12,2.09ZM39.71,23.91l-.54,0c-4.27,0-8.54,0-12.81,0a1.47,1.47,0,0,0-1.4.77c-3.1,4.84-6.23,9.67-9.33,14.52a1.18,1.18,0,0,1-1.22.65c-.83,0-1.67,0-2.57,0,.13-.41.23-.76.34-1.1l4.2-13a5.62,5.62,0,0,0,.19-.58.92.92,0,0,0-1-1.27c-1.33,0-2.65,0-4,0-1.78,0-3.56,0-5.33,0a4.31,4.31,0,0,1-4.43-4.4c0-2.75,0-5.51,0-8.26,1-.2,1-.2,1.32.67.63,1.61,1.25,3.23,1.88,4.85.38,1,.54,1.08,1.59,1.08H30.36c1.06,0,2.13,0,3.19,0,3.06.16,5.66,2.93,6.19,5.83A.56.56,0,0,1,39.71,23.91Z" />
    </svg>
  );
}

Plane.propTypes = {
  className: PropTypes.string
};

function CCard(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '28px'}
      height={props.height || '30px'}
      viewBox="0 0 47 31.41"
      aria-hidden="true"
      focusable="false">
      <path d="M47,3.33C47,1.1,46,0,43.91,0H3C.92,0,0,1.07,0,3.34V27.79c0,2.81.75,3.62,3.35,3.62q10,0,20.08,0t20.29,0c2.42,0,3.27-.9,3.27-3.53Q47,15.61,47,3.33ZM44,29.71H3c-1.35,0-1.46-.12-1.46-1.58q0-6.76,0-13.54a6.71,6.71,0,0,1,.07-.69H45.34c0,.31.08.59.08.87V28.1C45.42,29.58,45.31,29.71,44,29.71ZM1.62,12.12V7.69H45.37v4.43Zm43.8-6.29H1.55a26.52,26.52,0,0,1,0-3.1c0-.37.52-.75.88-1s.6,0,.91,0H43.63c1.75,0,1.78,0,1.79,1.89Z" />
      <path d="M14.73,24.68H6.36c-.56,0-1.13.16-1,.88a1.28,1.28,0,0,0,1,.76c1.39.09,2.79,0,4.18,0h4.19c.55,0,1-.05,1.07-.83S15.27,24.68,14.73,24.68Z" />
      <path d="M21.78,24.72a25,25,0,0,0-3.57,0c-.3,0-.55.55-.83.84.3.26.56.68.9.76a6.72,6.72,0,0,0,1.62,0,8.67,8.67,0,0,0,1.84,0c.31-.08.55-.54.82-.83C22.3,25.22,22.05,24.74,21.78,24.72Z" />
    </svg>
  );
}

CCard.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

function Paddle(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="22x"
      viewBox="0 0 45.34 38"
      aria-hidden="true"
      focusable="false">
      <path
        d="M40.58,18.31,30.51,3.25a1.83,1.83,0,0,0-1.23-.84h0a2.16,2.16,0,0,0-1,.48L12.14,13.69a2.93,2.93,0,0,0-.51.38,1.58,1.58,0,0,0-.42.82,3,3,0,0,0,.26,1.43l0,.06c.22.65.41,1.32.59,2,.08.32.17.63.26.94s.18.63.28.95c.2.64.4,1.31.57,2a3.61,3.61,0,0,1-1.22,4L3.16,32.12a2.74,2.74,0,0,1-.38.22l-.11,0a.65.65,0,0,0-.26.23,1.74,1.74,0,0,0,.33.63l1,1.54L4,35.1a3.84,3.84,0,0,0,.31.45l0,0a1.46,1.46,0,0,0,.94-.32l1.5-1,7.08-4.74.1-.07a3,3,0,0,1,.59-.37,2.75,2.75,0,0,1,1.57-.1,6.07,6.07,0,0,1,2.81,1.38c.48.4,1,.79,1.46,1.18l.74.59,1.49,1.19.27.22a3.27,3.27,0,0,0,.35.28,4.05,4.05,0,0,0,.88.33,2,2,0,0,0,1.43.07,7.71,7.71,0,0,0,.91-.59l.49-.34,14.91-10,.21-.14a4.93,4.93,0,0,0,.45-.31,1.33,1.33,0,0,0,.44-.62c0-.08.07-.32-.27-.83C42,20.36,41.27,19.34,40.58,18.31ZM27.82,19.67l-2.43,5.77-1.95-2.92L25,19.05l-3.81.14-1.95-2.9,6.33,0,2.26-5.34,1.92,2.87L28.19,17l3.56-.28,1.89,2.83Z"
        fill="none"
      />
      <path d="M44.65,20.05C44,19,43.28,18,42.59,17L32.51,1.9h0A4.09,4.09,0,0,0,29.34,0a4,4,0,0,0-2.41.89l-16,10.73-.08.06a5.35,5.35,0,0,0-.9.71,3.9,3.9,0,0,0-1.07,2.09,5.05,5.05,0,0,0,.36,2.61l0,.06c.2.59.36,1.19.54,1.83L10,20c.09.33.19.65.29,1,.2.64.38,1.25.54,1.87s.05,1.24-.22,1.42l-8.8,5.89-.11.05-.27.15a2.76,2.76,0,0,0-1.43,2A3.32,3.32,0,0,0,.73,34.6l1,1.53c.05.09.11.17.16.25a6.52,6.52,0,0,0,.49.71,2.46,2.46,0,0,0,2,.91,4,4,0,0,0,2.2-.75l1.5-1,7.08-4.74.21-.15.07-.05.27,0a3.58,3.58,0,0,1,1.65.87l1.49,1.2.73.58,1.5,1.2.2.17a4.62,4.62,0,0,0,.61.47,5.66,5.66,0,0,0,1.53.62,4.25,4.25,0,0,0,3.19-.1,10.08,10.08,0,0,0,1.21-.78l.43-.3,14.91-10,.18-.11a4.93,4.93,0,0,0,.7-.51,3.59,3.59,0,0,0,1.22-1.88A3.36,3.36,0,0,0,44.65,20.05Zm-1.74,2.17a1.33,1.33,0,0,1-.44.62,4.93,4.93,0,0,1-.45.31l-.21.14-14.91,10-.49.34a7.71,7.71,0,0,1-.91.59,2,2,0,0,1-1.43-.07,4.05,4.05,0,0,1-.88-.33,3.27,3.27,0,0,1-.35-.28l-.27-.22-1.49-1.19-.74-.59c-.48-.39-1-.78-1.46-1.18A6.07,6.07,0,0,0,16.07,29a2.75,2.75,0,0,0-1.57.1,3,3,0,0,0-.59.37l-.1.07L6.73,34.24l-1.5,1a1.46,1.46,0,0,1-.94.32l0,0A3.84,3.84,0,0,1,4,35.1l-.19-.31-1-1.54a1.74,1.74,0,0,1-.33-.63.65.65,0,0,1,.26-.23l.11,0a2.74,2.74,0,0,0,.38-.22L12,26.23a3.61,3.61,0,0,0,1.22-4c-.17-.67-.37-1.34-.57-2-.1-.32-.19-.63-.28-.95s-.18-.62-.26-.94c-.18-.64-.37-1.31-.59-2l0-.06a3,3,0,0,1-.26-1.43,1.58,1.58,0,0,1,.42-.82,2.93,2.93,0,0,1,.51-.38L28.27,2.89a2.16,2.16,0,0,1,1-.48h0a1.83,1.83,0,0,1,1.23.84L40.58,18.31c.69,1,1.38,2.05,2.06,3.08C43,21.9,42.93,22.14,42.91,22.22Z" />
      <polygon points="28.19 17.01 29.78 13.79 27.86 10.92 25.6 16.26 19.27 16.29 21.22 19.2 25.03 19.05 23.44 22.52 25.39 25.44 27.82 19.67 33.64 19.56 31.75 16.73 28.19 17.01" />
    </svg>
  );
}

Paddle.propTypes = {
  className: PropTypes.string
};

function Ticket(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 232.3 136.7"
      aria-hidden="true"
      focusable="false"
      width="20">
      <title>Ticket Icon</title>
      <g>
        <path
          className={props.className}
          d="M10.7,134.7c-4.8,0-8.7-3.9-8.7-8.7V97.2c0-1.6,1.2-2.9,2.8-3C17.9,93.4,28.4,83,29.2,69.9
		c0.4-6.9-1.9-13.6-6.5-18.7c-4.6-5.2-10.9-8.2-17.8-8.6c-1.6-0.1-2.8-1.4-2.8-3V10.7C2,5.9,5.9,2,10.7,2h210.9
		c4.8,0.1,8.6,3.9,8.7,8.6v28.9c0,1.7-1.3,3-3,3h-0.2c-14.3,0-25.9,11.6-25.9,25.9v0.1c0,14.2,11.6,25.7,25.8,25.7c0,0,0.1,0,0.1,0
		h0.2c1.7,0,3,1.3,3,3V126c-0.1,4.8-3.9,8.6-8.6,8.7H10.7z M8,99.9V126c0,1.5,1.2,2.7,2.7,2.7h210.9c1.5,0,2.7-1.2,2.7-2.8v-25.9
		c-16.2-1.4-29-15-29.1-31.6v-0.1c0-16.6,12.8-30.4,29.1-31.8V10.7c0-1.5-1.2-2.7-2.8-2.7L10.7,8C9.2,8,8,9.2,8,10.7v26.1
		c7.4,1.1,14.1,4.7,19.1,10.4c5.7,6.4,8.5,14.6,8,23.1C34.3,85.4,22.8,97.7,8,99.9z"
        />
      </g>
      <g>
        <rect x="168" y="10.2" className={props.className} width="6" height="6" />
      </g>
      <g>
        <rect x="168" y="22.4" className={props.className} width="6" height="91.9" />
      </g>
      <g>
        <rect x="168" y="120.5" className={props.className} width="6" height="6" />
      </g>
      <path
        className={props.className}
        d="M93.8,34.7l8.4,25.7h27l-21.9,15.9l8.4,25.7L93.8,86.1L72,102l8.3-25.7L58.5,60.4h27L93.8,34.7z"
      />
    </svg>
  );
}

Ticket.propTypes = {
  className: PropTypes.string
};

function VideoPlay() {
  return (
    <>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 20 20">
        <title>play</title>
        <path
          d="M10,16.588a7.0418,7.0418,0,1,1,7.0417-7.0417A7.05,7.05,0,0,1,10,16.588ZM10,3.66a5.8862,5.8862,0,1,0,5.8862,5.8862A5.8929,5.8929,0,0,0,10,3.66Z"
          fill="#fff"
        />
        <polygon
          points="12.902 9.546 10.646 10.849 8.39 12.151 8.39 9.546 8.39 6.942 10.646 8.244 12.902 9.546"
          fill="#fff"
        />
      </svg>
    </>
  );
}

function VideoPause() {
  return (
    <>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 20 20">
        <title>pause</title>
        <path
          d="M10,17.0417A7.0417,7.0417,0,1,1,17.0417,10,7.05,7.05,0,0,1,10,17.0417ZM10,4.1138A5.8862,5.8862,0,1,0,15.8862,10,5.8929,5.8929,0,0,0,10,4.1138Z"
          fill="#fff"
        />
        <rect x="7.6286" y="7.6767" width="1.4445" height="4.5983" fill="#fff" />
        <rect x="10.9509" y="7.6767" width="1.4445" height="4.5983" fill="#fff" />
      </svg>
    </>
  );
}

function FilterIcon() {
  return (
    <>
      <svg
        className="pb-1 pe-1"
        xmlns="http://www.w3.org/2000/svg"
        width="26px"
        height="24px"
        viewBox="0 0 26 24">
        <g id="Layer_1-2" data-name="Layer 1">
          <line
            y1="4.47"
            x2="26"
            y2="4.47"
            style={{ fill: '#fff', stroke: '#fff', strokeWidth: '2px' }}
          />
          <line
            y1="19.51"
            x2="26"
            y2="19.51"
            style={{ fill: '#fff', stroke: '#fff', strokeWidth: '2px' }}
          />
          <circle
            cx="9.03"
            cy="4.47"
            r="3.47"
            style={{ fill: '#fff', stroke: '#fff', strokeWidth: '2px' }}
          />
          <circle
            cx="17"
            cy="19.51"
            r="3.51"
            style={{ fill: '#fff', stroke: '#fff', strokeWidth: '2px' }}
          />
        </g>
      </svg>
    </>
  );
}

function Home() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.51 30"
        width="9px"
        height="10px"
        aria-hidden="true"
        focusable="false">
        <path
          d="M347.52,290.27l10.26,7.16V312.5H337.26V297.43l10.26-7.16m0-4.27-13.76,9.6V316h27.52V295.6L347.52,286Z"
          transform="translate(-333.76 -286)"
        />
      </svg>
    </>
  );
}

function Calendar() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 13"
        width="20px"
        height="20px"
        aria-hidden="true"
        focusable="false">
        <path d="M8.9851,1.2817H7.9831V2.33H5.0172V1.2817H4.0152V2.33H1.8061v9.3879h9.3878V2.33H8.9851Zm1.2068,2.0507v7.3838H2.8081V3.3324H4.0152V4.569h1.002V3.3324H7.9831V4.569h1.002V3.3324Z" />
      </svg>
    </>
  );
}

function Location() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 13"
        width="20px"
        height="20px"
        aria-hidden="true"
        focusable="false">
        <path d="M6.5,11.8089a.4886.4886,0,0,1-.3454-.1431L6.06,11.5716C4.529,10.0414,2.2154,7.73,2.2154,5.4757a4.2846,4.2846,0,0,1,8.5692,0c0,2.2538-2.3136,4.5657-3.8447,6.0959l-.0945.0942A.4886.4886,0,0,1,6.5,11.8089Zm0-9.6407A3.3112,3.3112,0,0,0,3.1925,5.4757c0,1.7369,1.9659,3.8065,3.3075,5.1535,1.3416-1.347,3.3075-3.4166,3.3075-5.1535A3.3112,3.3112,0,0,0,6.5,2.1682Z" />
        <path d="M6.5,11.8089a.4886.4886,0,0,1-.3454-.1431L6.06,11.5716C4.529,10.0414,2.2154,7.73,2.2154,5.4757a4.2846,4.2846,0,0,1,8.5692,0c0,2.2538-2.3136,4.5657-3.8447,6.0959l-.0945.0942A.4886.4886,0,0,1,6.5,11.8089Zm0-9.6407A3.3112,3.3112,0,0,0,3.1925,5.4757c0,1.7369,1.9659,3.8065,3.3075,5.1535,1.3416-1.347,3.3075-3.4166,3.3075-5.1535A3.3112,3.3112,0,0,0,6.5,2.1682Z" />
      </svg>
    </>
  );
}

function Capacity() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 13"
        width="20px"
        height="20px"
        aria-hidden="true"
        focusable="false">
        <path d="M11.5881,12.155H1.4119V10.0168c0-.9263,1.4823-1.9887,3.2412-2.3955a3.9568,3.9568,0,0,1-1.33-3.04A3.4951,3.4951,0,0,1,6.5.845,3.4945,3.4945,0,0,1,9.6768,4.5814a3.9568,3.9568,0,0,1-1.33,3.04c1.759.4068,3.2416,1.4692,3.2416,2.3955Zm-9.094-1.0828h8.0111V10.0168A4.2086,4.2086,0,0,0,7.6119,8.5843l-.4653-.0656V7.1274l.2581-.1586A2.8031,2.8031,0,0,0,8.594,4.5814,2.4268,2.4268,0,0,0,6.5,1.9278,2.4272,2.4272,0,0,0,4.4057,4.5814a2.8033,2.8033,0,0,0,1.19,2.3874l.2581.1586V8.5187l-.4657.0656a4.0981,4.0981,0,0,0-2.895,1.4579Z" />
      </svg>
    </>
  );
}

function Price() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 13"
        width="20px"
        height="20px"
        aria-hidden="true"
        focusable="false">
        <path d="M10.9937,2.0063l-.0216,3.8953L6.2686,10.605,2.395,6.7314,7.0985,2.0279l3.8952-.0216M11.95,1.05l-5.2482.0292L1.05,6.7314l5.219,5.219,5.6526-5.6526L11.95,1.05Z" />
        <path d="M9.0847,3.2494a.6659.6659,0,1,0,.4709.195.664.664,0,0,0-.4709-.195Z" />
        <path d="M10.9937,2.0063l-.0216,3.8953L6.2686,10.605,2.395,6.7314,7.0985,2.0279l3.8952-.0216M11.95,1.05l-5.2482.0292L1.05,6.7314l5.219,5.219,5.6526-5.6526L11.95,1.05Z" />
        <path d="M9.0847,3.2494a.6659.6659,0,1,0,.4709.195.664.664,0,0,0-.4709-.195Z" />
      </svg>
    </>
  );
}

function Check() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        aria-hidden="true"
        focusable="false"
        viewBox="0 0 19 16">
        <path
          d="M15.2027 0.027894L15.234 0L15.9551 0.799163L19 3.9219L18.9891 3.93166L19 3.94282L7.2381 16L6.27619 15.0139L6.27755 15.0112L0 8.56764L0.963265 7.5802L0.963265 7.58159L3.84082 4.6318L4.69796 5.50907L4.66939 5.53696L6.93469 7.85774L14.9456 0.271967L15.1932 0.0195258L15.2027 0.027894ZM17 3.95884L15.1138 2L7.01025 9.7674L6.26433 8.92809L3.90625 6.4837L2 8.46197L7.32573 14L17 3.95884Z"
          id="Fill-1"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
      </svg>
    </>
  );
}

function AddtoCalendar() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26">
        <path
          d="M6.3139,2a.62.62,0,0,0-.62.62V3.8566H2.9089a.9285.9285,0,0,0-.9319.9249V23.3507a.9284.9284,0,0,0,.9285.9283H21.4748a.9284.9284,0,0,0,.9283-.9283V4.7849a.9285.9285,0,0,0-.9283-.9283H18.69V2.62A.62.62,0,0,0,18.07,2h-.6164a.62.62,0,0,0-.62.62V3.8566H7.55V2.62A.62.62,0,0,0,6.93,2ZM3.8372,5.7132H20.5465V7.57H3.8372Zm0,3.7132H20.5465v12.996H3.8372Zm1.8566,1.8565V13.14H7.55V11.2829Zm3.7132,0V13.14h1.8566V11.2829Zm3.7131,0V13.14h1.8566V11.2829Zm3.7132,0V13.14H18.69V11.2829Zm-11.14,3.7132v1.8566H7.55V14.9961Zm3.7132,0v1.8566h1.8566V14.9961Zm3.7131,0v1.8566h1.8566V14.9961Zm3.7132,0v1.8566H18.69V14.9961Zm-11.14,3.7132v1.8566H7.55V18.7093Zm3.7132,0v1.8566h1.8566V18.7093Zm3.7131,0v1.8566h1.8566V18.7093Z"
          fill="#000000"
        />
      </svg>
    </>
  );
}

export const Icons = () => {
  return (
    <div className="">
      <Instagram className="mx-2" />
      <Facebook className="mx-2" />
      <Plane />
      <CCard />
      <Paddle />
      <Ticket />
      <VideoPlay />
      <VideoPause />
      <FilterIcon />
      <Home />
      <Calendar />
      <AddtoCalendar />
      <Location />
      <Capacity />
      <Price />
      <Check />
    </div>
  );
};

export {
  Instagram,
  Facebook,
  Plane,
  CCard,
  Paddle,
  Ticket,
  VideoPlay,
  VideoPause,
  FilterIcon,
  Home,
  Calendar,
  AddtoCalendar,
  Location,
  Capacity,
  Price,
  Check
};

export default Icons;
