import { useContext } from 'react';
import ExperienceTile from '../ExperienceTile/ExperienceTile';
import { GlobalContext } from './../../contexts/globalContext';

const ExperienceTiles = () => {
  const globalData = useContext(GlobalContext);

  return (
    <div className="mt-5" aria-labelledby="elevateExperience">
      <h2 className="border-bottom border-grey py-2 mb-4 font-neue-plak-med" id="elevateExperience">
        Elevate your Experience
      </h2>
      <div className="row row-cols-2">
        {globalData !== undefined &&
          globalData.api.evergreenTiles !== undefined &&
          globalData.api.evergreenTiles.map((tile, idx) => (
            <ExperienceTile
              key={idx}
              copy={tile.copy}
              imageURL={tile.imageURL}
              imageAltText={tile.imageAltText}
              isEventPage={true}
              linkAriaText={tile.linkAriaText}
              linkCopy={tile.linkCopy}
              linkDestination={tile.linkDestination}
              linkIsOffsite={tile.linkIsOffsite}
              logoURL={tile.logoURL}
              logoAriaText={tile.logoAriaText}
            />
          ))}
      </div>
    </div>
  );
};

export default ExperienceTiles;
