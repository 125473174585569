import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PastEventListingPage from './pages/pastEventListingPage';
import UpcomingEventListingPage from './pages/upcomingEventListingPage';
import DetailPage from './pages/detailPage';
import GalleryPage from './pages/galleryPage';
import Header from './partials/Header/Header';
import Footer from './partials/Footer/Footer';
import SitemapPage from './pages/sitemapPage';
import TagManager from 'react-gtm-module';

// Set environment-specific variables
let GTM_KEY = 'GTM-P2MPRL';
window.GOOGLE_MAPS_KEY = 'AIzaSyCvpclkfWxoqmKbC5jLsAw8cXz90BUVne4';
window.API_URL = 'https://www.unitedcardevents.com/';

if (
  window.location.host.indexOf('uce.dev') !== -1 ||
  window.location.host.indexOf('localhost') !== -1
) {
  GTM_KEY = 'GTM-T46HGJ8';
  window.GOOGLE_MAPS_KEY = 'AIzaSyCvpclkfWxoqmKbC5jLsAw8cXz90BUVne4';
  window.API_URL = 'https://uce-api.dev.archer-group.com/';
} else if (window.location.host.indexOf('uce.staging') !== -1) {
  GTM_KEY = 'GTM-T46HGJ8';
  window.GOOGLE_MAPS_KEY = 'AIzaSyCvpclkfWxoqmKbC5jLsAw8cXz90BUVne4';
  window.API_URL = 'https://uce-api.staging.archer-group.com/';
} else if (window.location.host.indexOf('uce.uat') !== -1) {
  GTM_KEY = 'GTM-T46HGJ8';
  window.GOOGLE_MAPS_KEY = 'AIzaSyCvpclkfWxoqmKbC5jLsAw8cXz90BUVne4';
  window.API_URL = 'https://uce-api.uat.archer-group.com/';
} else if (window.location.host.indexOf('uce.prod') !== -1) {
  GTM_KEY = 'GTM-P2MPRL';
  window.GOOGLE_MAPS_KEY = 'AIzaSyCvpclkfWxoqmKbC5jLsAw8cXz90BUVne4';
  window.API_URL = 'https://uce-api.prod.archer-group.com/';
}

const tagManagerArgs = {
  gtmId: GTM_KEY
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <Router>
      <a href="#contentstart" id="skiplink">
        Skip to Main Content
      </a>
      <Header />
      <span id="contentstart"></span>
      <Routes>
        <Route path="/sitemap" element={<SitemapPage />} />
        <Route path="/past-events" element={<PastEventListingPage />} />
        <Route path="/:series/:id/gallery" element={<GalleryPage />} />
        <Route path="/:id/gallery" element={<GalleryPage />} />
        <Route path="/:series/:id" element={<DetailPage />} />
        <Route path="/:id" element={<DetailPage />} />
        <Route path="/" element={<UpcomingEventListingPage />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
