import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

import { EventStateEnum } from './../../enums/enums';
import pushDataLayer from '../../utilities/analytics';
import {
  getOnSaleCalendarLink,
  getEventDateCalendarLink,
  getSweepstakesEntryCalendarLink
} from '../../utilities/calendar';

const CalendarDownload = (props) => (
  <>
    {(props.eventState === EventStateEnum.PRESALE ||
      props.eventState === EventStateEnum.POSTPRESALE ||
      props.eventState === EventStateEnum.UPCOMING) && (
      <Button
        variant="link"
        onClick={() =>
          pushDataLayer({
            event: 'addToCalConfirmation'
          })
        }
        className="hover-no-underline hover-purple text-primary py-1 text-start font-neue-plak-med h5 ps-0 d-flex"
        href={getOnSaleCalendarLink(props.eventURL.replace(/^\/+/, '').replace(/\/+$/, ''))}
        aria-label="Download Calendar Invite (ics) for On Sale Date">
        On Sale Date
      </Button>
    )}
    {props.eventState === EventStateEnum.SWEEPSTAKESUPCOMING && (
      <Button
        variant="link"
        className="ps-0 font-neue-plak-med h5"
        href={getSweepstakesEntryCalendarLink(
          props.eventURL.replace(/^\/+/, '').replace(/\/+$/, '')
        )}
        onClick={() =>
          pushDataLayer({
            event: 'addToCalConfirmation'
          })
        }
        aria-label="Download Calendar Invite (ics) for Sweepstakes Entry Date">
        Entry Date
      </Button>
    )}
    <Button
      variant="link"
      className="hover-no-underline hover-purple text-primary py-1 text-start font-neue-plak-med h5 ps-0 d-flex"
      href={getEventDateCalendarLink(props.eventURL.replace(/^\/+/, '').replace(/\/+$/, ''))}
      onClick={() =>
        pushDataLayer({
          event: 'addToCalConfirmation'
        })
      }
      aria-label="Event Date Download Calendar Invite (ics)">
      Event Date
    </Button>
  </>
);

export default CalendarDownload;

CalendarDownload.propTypes = {
  className: PropTypes.string,
  eventState: PropTypes.number.isRequired,
  eventURL: PropTypes.string.isRequired
};
