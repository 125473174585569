import React, { useContext, useEffect, useState } from 'react';
import { WebIdDispatchContext } from '../contexts/navigationContext';
import { getSitemapContent } from '../utilities/api';
import { Container, Row, Col } from 'react-bootstrap';
import { NagivationHighlightEnum } from '../enums/enums';
import { NavigationHighlightDispatchContext } from '../contexts/navigationContext';

import { Helmet } from 'react-helmet';

const SitemapPage = () => {
  const setWebId = useContext(WebIdDispatchContext);
  const [sitemapData, setSitemapData] = useState(undefined);

  const setNavigationHighlightState = useContext(NavigationHighlightDispatchContext);

  useEffect(() => {
    setNavigationHighlightState(NagivationHighlightEnum.NONE);
  }, [setNavigationHighlightState]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);

    getSitemapContent()
      .then((res) => res.json())
      .then((data) => {
        setSitemapData(data);
        setWebId('');
      });
  }, [setWebId]);

  return (
    <>
      <Helmet>
        <title>Sitemap</title>
      </Helmet>
      <Container as="main" className="py-4">
        <Row>
          <Col>
            <h1 className="h3">Upcoming Experiences</h1>
            <ul>
              {sitemapData !== undefined &&
                sitemapData.upcomingEvents.map((e, idx) => (
                  <li key={idx} className="my-1">
                    <a
                      href={e.url}
                      className="text-ia-blue-link text-decoration-none hover-purple hover-underline">
                      {e.title}
                    </a>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="h3 mt-3">Past Experiences</h1>
            <ul>
              {sitemapData !== undefined &&
                sitemapData.pastEvents.map((e, idx) => (
                  <li key={idx}>
                    <a
                      href={e.url}
                      className="text-ia-blue-link text-decoration-none hover-purple hover-underline">
                      {e.title}
                    </a>
                    {e.hasGallery && (
                      <ul>
                        <li>
                          <a
                            href={`${e.url}gallery`}
                            className="text-ia-blue-link text-decoration-none hover-purple hover-underline">
                            Photo Gallery page of {e.title}
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SitemapPage;
