import PropTypes from 'prop-types';
import CarouselContent from './CarouselContent';
import Details from './Details';
import ExperienceTiles from './ExperienceTiles';
import ExperienceIncludes from './ExperienceIncludes';
import Location from './Location';
import Packages from './Packages';
import Schedule from './Schedule';
import SeriesTile from './SeriesTile';
import Title from './Title';
import { EventTypeEnum, EventStateEnum } from './../../enums/enums';
import { Col } from 'react-bootstrap';

const EventArticle = (props) => (
  <Col md={9} as="article" aria-label="Event description">
    <Title title={props.title} category={props.category} />
    <CarouselContent featuredImages={props.featuredImages} />
    <span id="eventDetails"></span>
    <Details details={props.details} />
    <ExperienceIncludes experienceIncludes={props.experienceIncludes} />
    {props.eventType !== EventTypeEnum.SERIES_PARENT && (
      <>
        <Schedule
          schedule={props.schedule}
          scheduleDisclaimer={props.scheduleDisclaimer}
          scheduleHeaderAdditionalText={props.scheduleHeaderAdditionalText}
        />
        <Packages packages={props.packages} />
        <Location locations={props.locations} />
        {props.isSweepstakes && (
          <>
            <div id="terms-sweeps"></div>
            <div
              className="container"
              dangerouslySetInnerHTML={{ __html: props.offerTermsAndConditions }}></div>
          </>
        )}
      </>
    )}
    {props.eventState !== EventStateEnum.POSTPONED &&
      props.eventState !== EventStateEnum.PAST &&
      props.eventState !== EventStateEnum.CANCELLED &&
      props.eventType !== EventTypeEnum.SERIES_PARENT && <ExperienceTiles />}

    {props.eventType === EventTypeEnum.SERIES_PARENT && (
      <>
        <div className="mt-5">
          <h3 className="border-bottom border-grey py-2"> Series </h3>
          {props.upcomingEvents.map((ev, idx) => (
            // TODO: Kory find/add a series event to style from
            <SeriesTile key={idx} {...ev} />
          ))}
        </div>
      </>
    )}
  </Col>
);

export default EventArticle;

EventArticle.propTypes = {
  eventType: PropTypes.number.isRequired,
  eventState: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  locations: PropTypes.array,
  schedule: PropTypes.string,
  scheduleDisclaimer: PropTypes.string,
  scheduleHeaderAdditionalText: PropTypes.string,
  featuredImages: PropTypes.array,
  packages: PropTypes.array,
  details: PropTypes.string,
  experienceIncludes: PropTypes.string,
  upcomingEvents: PropTypes.array,
  isSweepstakes: PropTypes.bool,
  offerTermsAndConditions: PropTypes.string
};
