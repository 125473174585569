import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Stack } from 'react-bootstrap';
import FilterCheck from './FilterCheck';
import FilterPill from './FilterPill';
import { FilterIcon } from './../Icons/Icons';
import ButtonMain from '../ButtonMain/ButtonMain';
// import useBrowserSize from '../../hooks/browser-size';

const FilterDisplay = (props) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const handleApply = () => {
    props.updateFilter();
    setShowFilterModal(false);
  };

  useEffect(() => {
    setFilterCount(props.countFilters);
  }, [props.updateFilter]);

  const pills = [];
  pills.push(
    props.regions
      .filter((p) => p.isChecked)
      .map((p) => (
        <FilterPill
          key={`regions_${p.label}`}
          filter="regions"
          label={p.label}
          updateFilter={props.updateFilter}
          idx={p.idx}
        />
      ))
  );
  pills.push(
    props.categories
      .filter((p) => p.isChecked)
      .map((p) => (
        <FilterPill
          key={`categories_${p.label}`}
          filter="categories"
          label={p.label}
          updateFilter={props.updateFilter}
          idx={p.idx}
        />
      ))
  );
  if (props.showDates) {
    pills.push(
      props.months
        .filter((p) => p.isChecked)
        .map((p) => (
          <FilterPill
            key={`months_${p.label}`}
            filter="months"
            label={p.label}
            updateFilter={props.updateFilter}
            idx={p.idx}
          />
        ))
    );
    pills.push(
      props.years
        .filter((p) => p.isChecked)
        .map((p) => (
          <FilterPill
            key={`years_${p.label}`}
            filter="years"
            label={p.label}
            updateFilter={props.updateFilter}
            idx={p.idx}
          />
        ))
    );
  }

  return (
    <>
      <Container as="section" className="position-relative">
        <Row className="d-flex justify-content-center justify-content-start-md">
          {/* Mobile Accordion Wrapper */}
          <Col className="col-12 col-md-auto d-flex justify-content-center align-content-center py-2 py-md-4 px-0">
            <ButtonMain
              label="Filter Events"
              icon={<FilterIcon />}
              className="align-self-center px-md-4 col-5 col-md-auto text-nowrap px-0 font-neue-plak-med  mt-n4 mt-md-0"
              aria-label="Filter Events button opens a modal for filter selections"
              onClick={() => setShowFilterModal(true)}
            />
          </Col>
          {/* Filter Badges */}
          <Col id="badges" aria-live="polite" className="align-content-center">
            <p className="visually-hidden">Currently there are {filterCount} filters selected.</p>
            {pills && <div className="py-4 ">{pills}</div>}
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        aria-labelledby="filter-modal">
        <Modal.Header className="" closeButton>
          <Modal.Title>
            <h1 id="filter-modal" className="h3 font-neue-plak-med">
              Filter Events
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-md-4">
          <Container className="px-xs-0 px-sm-2 px-md-5">
            <fieldset>
              <legend className="h5 font-neue-plak-med pt-4">Regions</legend>
              <Row className="border-bottom border-grey py-2">
                {props.regions.map((f) => (
                  <Col
                    xs={6}
                    lg={4}
                    className="d-flex pe-3 py-md-2"
                    key={`${props.filter}_${f.label}`}>
                    <FilterCheck
                      filter="regions"
                      isChecked={f.isChecked}
                      label={f.label}
                      idx={f.idx}
                      updateFilter={props.updateFilter}
                    />
                  </Col>
                ))}
              </Row>
            </fieldset>
            <fieldset>
              <legend className="h5 font-neue-plak-med pt-4">Categories</legend>
              <Row className="border-bottom border-grey py-2">
                {props.categories.map((f) => (
                  <Col
                    xs={6}
                    lg={4}
                    className="d-flex pe-3 py-md-2"
                    key={`${props.filter}_${f.label}`}>
                    <FilterCheck
                      filter="categories"
                      label={f.label}
                      idx={f.idx}
                      updateFilter={props.updateFilter}
                    />
                  </Col>
                ))}
              </Row>
            </fieldset>
            <fieldset>
              <legend className="h5 font-neue-plak-med pt-4">Month</legend>
              <Row className="border-bottom border-grey py-2">
                {props.months.map((f) => (
                  <Col
                    xs={6}
                    lg={4}
                    className="d-flex pe-3 py-md-2"
                    key={`${props.filter}_${f.label}`}>
                    <FilterCheck
                      filter="months"
                      isChecked={f.isChecked}
                      label={f.label}
                      idx={f.idx}
                      updateFilter={props.updateFilter}
                    />
                  </Col>
                ))}
              </Row>
            </fieldset>
            <fieldset>
              <legend className="h5 font-neue-plak-med  pt-4">Year</legend>
              <Row className="border-bottom border-grey py-2">
                {props.years.map((f) => (
                  <Col
                    xs={6}
                    lg={4}
                    className="d-flex pe-3 py-md-2"
                    key={`${props.filter}_${f.label}`}>
                    <FilterCheck
                      filter="years"
                      isChecked={f.isChecked}
                      label={f.label}
                      idx={f.idx}
                      updateFilter={props.updateFilter}
                    />
                  </Col>
                ))}
              </Row>
            </fieldset>
            <Stack gap={3} className="col-md-5 mx-auto pt-4">
              <ButtonMain
                label="Apply"
                className="align-self-center px-lg-3 col-5 text-nowrap px-0 font-neue-plak-med"
                aria-label={`Apply ${filterCount} filters and close modal to view events that match your selection`}
                onClick={handleApply}
              />
              <ButtonMain
                label="Clear All Filters"
                aria-label="Clear all filters that are currently selected"
                variant="link"
                className="align-self-center px-lg-3 col-5 col-lg-auto text-nowrap px-0 font-neue-plak-med pb-3"
                onClick={() => {
                  props.clearFilters();
                }}
              />
            </Stack>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

FilterDisplay.propTypes = {
  categories: PropTypes.array,
  isDesktop: PropTypes.bool,
  months: PropTypes.array,
  paymentTypes: PropTypes.array,
  regions: PropTypes.array,
  showDates: PropTypes.bool,
  showPaymentTypes: PropTypes.bool,
  updateFilter: PropTypes.func,
  clearFilters: PropTypes.func,
  countFilters: PropTypes.func,
  eventKey: PropTypes.string,
  filter: PropTypes.string,
  filters: PropTypes.array,
  label: PropTypes.string,
  years: PropTypes.array
};

export default FilterDisplay;
