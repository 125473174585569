import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import pushDataLayer from '../../utilities/analytics';

export const PurchaseButton = (props) => {
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);

  const { href, isWaitList, analyticsData, ...cleanProps } = props;

  return (
    <>
      <Button
        as="button"
        {...cleanProps}
        onClick={() => {
          pushDataLayer(analyticsData);
          setShowPurchaseModal(true);
        }}>
        {/* Update Purchase Button text based on Waitlist condition */}
        {props.isWaitList ? (
          <span className="font-neue-plak-med">Add to Waitlist</span>
        ) : (
          <span className="font-neue-plak-med">Purchase Now</span>
        )}
      </Button>

      <Modal size="lg" show={showPurchaseModal} onHide={() => setShowPurchaseModal(false)}>
        <Modal.Header className="align-items-start" closeButton>
          <Modal.Title>
            {isWaitList && <h1 className="display-6">Add to waitlist</h1>}
            {!isWaitList && <h1 className="display-6">Purchase</h1>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          {isWaitList && (
            <p>
              You will be directed to Chase.com to add your name to the waitlist by logging into
              your Chase.com account. Once you have logged into your account, you will be directed
              to add your name to the waitlist.
            </p>
          )}
          {!isWaitList && (
            <p>
              You will be directed to Chase.com to complete your purchase by logging into your
              Chase.com account. Once you have logged into your account, you will be directed to the
              checkout page to complete your purchase.
            </p>
          )}
          <Button as="a" href={href} target="_blank">
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

PurchaseButton.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  isWaitList: PropTypes.bool,
  analyticsData: PropTypes.object
};

export default PurchaseButton;
