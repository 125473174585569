const EventStateEnum = Object.freeze({
  INACTIVE: 0,
  COMINGSOON: 1,
  UPCOMING: 2,
  PRESALE: 3,
  POSTPRESALE: 4,
  ONSALE: 5,
  SOLDOUT: 6,
  CANCELLED: 7,
  POSTPONED: 8,
  PAST: 9,
  SWEEPSTAKESUPCOMING: 100,
  SWEEPSTAKESOPEN: 101,
  SWEEPSTAKESCLOSED: 102,
  BLANK: 999
});

const EventTypeEnum = Object.freeze({
  NORMAL: 0,
  SERIES_PARENT: 1,
  SERIES_CHILD: 2,
  MP: 3
});

const PaymentTypeEnum = Object.freeze({
  PAY_CARD: 0,
  PAY_MILES: 1,
  BID_MILES: 2,
  SWEEPSTAKES: 3
});

const NagivationHighlightEnum = Object.freeze({
  NONE: 0,
  UPCOMING: 1,
  PAST: 2
});

export { EventStateEnum, EventTypeEnum, NagivationHighlightEnum, PaymentTypeEnum };
