import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import { GlobalContextProvider } from './contexts/globalContext';
import { NavigationContextProvider } from './contexts/navigationContext';
import { FilterContextProvider } from './contexts/filterContext';
import './main.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <NavigationContextProvider>
        <FilterContextProvider>
          <App />
        </FilterContextProvider>
      </NavigationContextProvider>
    </GlobalContextProvider>
  </React.StrictMode>
);
