const getCurrentExperiences = () =>
  fetch(`${window.API_URL}content/getcurrentevents?_=${new Date().getTime()}`);

const getGlobalContent = () => fetch(`${window.API_URL}content/global?_=${new Date().getTime()}`);

const getSingleEventData = (id) =>
  fetch(`${window.API_URL}content/event?slug=${id}&_=${new Date().getTime()}`);

const getPastEvents = (page) =>
  fetch(`${window.API_URL}content/getpastevents?page=${page}&_=${new Date().getTime()}`);

const getSitemapContent = () => fetch(`${window.API_URL}content/sitemap?_=${new Date().getTime()}`);

export {
  getSingleEventData,
  getCurrentExperiences,
  getGlobalContent,
  getPastEvents,
  getSitemapContent
};
