import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Calendar, Location, Capacity, Price, CCard, AddtoCalendar } from './../Icons/Icons';

import ButtonMain from './../ButtonMain/ButtonMain';
import AsideFacet from './AsideFacet';
import { eventStateEnumToStatusTextForDetailPage } from './../../utilities/enumTranslation';
import { Col, Button } from 'react-bootstrap';

import CalendarDownload from '../Social/CalendarDownload';
import FacebookShare from '../Social/FacebookShare';
import TwitterShare from '../Social/TwitterShare';
import ContentModal from '../ContentModal/ContentModal';
import { EventStateEnum } from '../../enums/enums';
import { PurchaseButton } from '../ButtonMain/PurchaseButton';
import pushDataLayer from '../../utilities/analytics';
import moment from 'moment/moment';
import PhoneButton from '../ButtonMain/PhoneButton';

export const EventAside = (props) => {
  const [showOfferTermsModal, setShowOfferTermsModal] = useState(false);
  const location = useLocation();

  //pull and split dates from raw DateTime obj
  const saleMoment = moment(props.onSaleDate);
  const onSaleDate = saleMoment.format('MM[.]DD[.]YYYY');
  const onSaleTime = saleMoment.utc().format('hh:mmA [ET]');

  useEffect(() => {
    if (location.hash == '#additional') {
      setShowOfferTermsModal(true);
    }
  }, []);

  //TODO: Kory, set color styles here per status
  const getBorderColor = (eventState) => {
    switch (eventState) {
      case EventStateEnum.COMINGSOON:
        return 'border-lavendar';
      case EventStateEnum.UPCOMING:
        return 'border-light-blue';
      case EventStateEnum.PRESALE:
        return 'border-lavendar';
      case EventStateEnum.POSTPRESALE:
        return 'border-lavendar';
      case EventStateEnum.ONSALE:
        return 'border-yellow';
      case EventStateEnum.SOLDOUT:
        return 'border-dk-grey';
      case EventStateEnum.CANCELLED:
        return 'border-dk-grey';
      case EventStateEnum.POSTPONED:
        return 'border-dk-grey';
      case EventStateEnum.SWEEPSTAKESUPCOMING:
        return 'border-grey';
      case EventStateEnum.SWEEPSTAKESOPEN:
        return 'border-yellow';
      case EventStateEnum.SWEEPSTAKESCLOSED:
        return 'border-dk-grey';
      case EventStateEnum.INACTIVE:
        return '';
      case EventStateEnum.PAST:
        return '';
      case EventStateEnum.BLANK:
        return '';
      default:
        return 'border-grey';
    }
  };

  const dataNavigationBody = {
    event: 'navigationClick',
    attributes: {
      location: 'body'
    }
  };

  return (
    <>
      <Col md={3} as="aside" aria-label="Event Details" className="pb-3">
        <div className="sticky-md-top">
          {props.eventState != EventStateEnum.BLANK && (
            <h2
              className={`border-bottom border-2 h5 ${getBorderColor(
                props.eventState
              )} pb-1 text-uppercase pt-3`}>
              {eventStateEnumToStatusTextForDetailPage(
                props.eventState,
                props.paymentType,
                onSaleDate,
                onSaleTime,
                props.sweepstakesStartDateLongText
              )}
            </h2>
          )}
          {/* Mobile Only */}
          <h2 className="h5 d-md-none text-uppercase">{props.category}</h2>
          <h3 className="display-6 d-md-none pb-3">{props.title}</h3>
          {/* end Mobile Only */}
          <ul className="row p-0 row-cols-md-1 row-cols-2 mb-0">
            {new Date(props.eventDate).getFullYear() != 1 && (
              <AsideFacet icon={<Calendar />} title="Event Date" body={props.eventDateText} />
            )}
            <AsideFacet icon={<Location />} title="Location" body={props.locationText} />
            {props.packages !== undefined &&
              props.packages.length == 1 &&
              props.packages[0].capacity != 0 &&
              props.packages[0].capacity != '' && (
                <AsideFacet
                  icon={<Capacity />}
                  title="Capacity"
                  body={props.packages[0].capacity}
                />
              )}
            {props.packages !== undefined && props.packages.length < 1 && (
              <>
                <AsideFacet icon={<Capacity />} title="Capacity" body="See Series Details Below" />
              </>
            )}
            {props.packages !== undefined && props.packages.length > 1 && (
              <AsideFacet
                icon={<Capacity />}
                title="Capacity"
                keyValueBody={props.packages.map((p) => {
                  return { key: p.name, value: p.capacity };
                })}
              />
            )}
            {!props.packages && (
              <AsideFacet icon={<Capacity />} title="Capacity" body="See Series Details Below" />
            )}
            {props.isSweepstakes && (
              <AsideFacet
                icon={<Price />}
                title="Enter for a Chance to Win"
                body={
                  props.sweepsstakesLeftRailCopy !== undefined ? (
                    props.sweepsstakesLeftRailCopy
                  ) : (
                    <>
                      No purchase necessary
                      <br />
                      One entry per person
                    </>
                  )
                }
              />
            )}

            {!props.isSweepstakes && props.packages !== undefined && props.packages.length == 1 && (
              <AsideFacet
                icon={<CCard width="20px" />}
                title="Price"
                body={props.packages[0].price}
                onClick={() => {
                  pushDataLayer(dataNavigationBody);
                }}
                subtext={!props.isEventFree && <small className="mb-0">Pay with your card</small>}
              />
            )}

            {!props.isSweepstakes && props.packages !== undefined && props.packages.length > 1 && (
              <AsideFacet
                icon={<CCard width={'20px'} height={'20px'} />}
                title="Price"
                onClick={() => {
                  pushDataLayer(dataNavigationBody);
                }}
                keyValueBody={props.packages.map((p) => {
                  return { key: p.name, value: p.price };
                })}
                subtext={<span>Pay with your card</span>}
              />
            )}

            {!props.packages && (
              <AsideFacet icon={<Price />} title="Price" body="See Series Details Below" />
            )}

            {/* Call to purchase / Call center hours */}
            {props.eventState === EventStateEnum.ONSALE &&
              props.phoneNumber !== undefined &&
              props.phoneNumber !== '' &&
              !props.isWaitList &&
              !props.hideCallToPurchase && (
                <>
                  <PhoneButton
                    as="a"
                    className="d-block d-md-none w-100 my-2"
                    href={`tel:${props.phoneNumber}`}
                    aria-label={`Click here to call ${props.phoneNumber} to purchase`}
                    analyticsData={dataNavigationBody}
                    label={props.phoneNumber}
                  />
                  <p className="d-block d-md-none mt-2 mb-0 small">Call to purchase</p>
                  <p className="d-block d-md-none">{props.phoneNumber}</p>

                  {props.callCenterHours != undefined &&
                    props.callCenterHours != null &&
                    props.callCenterHours != '' && (
                      <>
                        <p className="d-block d-md-none small text-uppercase mb-0">
                          Call Center Hours
                        </p>
                        <p className="d-block d-md-none">{props.callCenterHours}</p>
                      </>
                    )}
                </>
              )}

            {/* calendar */}
            {props.eventState !== EventStateEnum.PAST &&
              props.packages !== undefined &&
              props.packages.length && (
                <AsideFacet
                  icon={<AddtoCalendar />}
                  title="Add To Calendar"
                  subtext={
                    <CalendarDownload
                      className="p-0 hover-fade"
                      eventURL={props.eventURL}
                      eventState={props.eventState}
                    />
                  }
                  onClick={() => {
                    pushDataLayer(dataNavigationBody);
                  }}
                />
              )}
            {/* Sweeps rules / Offer T&C button */}

            {props.eventState !== EventStateEnum.PAST &&
              !props.isSweepstakes &&
              props.packages !== undefined &&
              props.packages.length && (
                <li className="d-flex mb-3">
                  <ButtonMain
                    className="hover-no-underline hover-purple text-primary py-2 text-start font-neue-plak-med h5 ps-0"
                    variant="link"
                    aria-label="Opens modal to event offer terms and conditions"
                    label="Offer Terms &amp; Conditions"
                    onClick={() => {
                      setShowOfferTermsModal(true);
                      pushDataLayer(dataNavigationBody);
                    }}
                  />
                </li>
              )}
          </ul>
          {/* Photo Gallery Button */}
          {props.hasGallery && (
            <ButtonMain
              as="a"
              role=""
              className="w-100 text-uppercase px-2 mb-3"
              href={`${props.eventURL}gallery`}
              label="View Event Photos"
              aria-label={`View Event Photos for ${props.title}`}
              onClick={() => {
                pushDataLayer(dataNavigationBody);
              }}
            />
          )}
          {/* Event photos coming soon */}
          {!props.hasGallery && props.eventState == EventStateEnum.PAST && (
            <p className="text-black small text-start">Event photos coming soon.</p>
          )}
          {/* Purchase Button */}
          {props.eventState === EventStateEnum.ONSALE &&
            props.registrationURL !== undefined &&
            props.registrationURL != null &&
            props.registrationURL != '' &&
            !props.isWaitList && (
              <PurchaseButton
                href={props.registrationURL}
                aria-label={`Purchase Now: Click here to purchase ${props.title}`}
                analyticsData={{
                  event: 'eventTileClick',
                  attributes: {
                    tile_action: 'Event Tile Purchase Click'
                  }
                }}
                className="d-none d-md-block mt-2 mb-3 px-3 w-100 text-uppercase"
              />
            )}
          {props.eventState !== EventStateEnum.PAST && props.isSweepstakes && (
            <Button
              variant="link"
              className="px-0 font-neue-plak-med text-start h5 py-2"
              href="#terms-sweeps"
              onClick={() => {
                pushDataLayer(dataNavigationBody);
              }}>
              Official Rules
            </Button>
          )}
          {/* Sweepstakes entry button */}
          {props.eventState === EventStateEnum.SWEEPSTAKESOPEN &&
            props.sweepstakesEntryURL != undefined &&
            props.sweepstakesEntryURL != null &&
            props.sweepstakesEntryURL != '' && (
              <ButtonMain
                as="a"
                href={props.sweepstakesEntryURL}
                isOffsite={true}
                label="Enter Now"
                aria-label={`Click here to Enter now to purchase ${props.title}`}
                onClick={() => {
                  pushDataLayer(dataNavigationBody);
                }}
                className="w-100 text-uppercase position-relative mb-3"
              />
            )}
          {/* Wait List */}
          {props.eventState !== EventStateEnum.PAST && props.isWaitList && (
            <>
              <PurchaseButton
                href={props.registrationURL}
                isWaitList={true}
                aria-label="Purchase online at chase.com"
                onClick={() => {
                  pushDataLayer(dataNavigationBody);
                }}
                className="w-100 text-uppercase my-3 d-none d-lg-block"
              />
              <p className="d-block d-lg-none">
                To add your name to the waitlist call&nbsp;
                <a
                  href="tel:+18887108256"
                  onClick={() => {
                    pushDataLayer(dataNavigationBody);
                  }}
                  aria-label="Call 1-888-710-8256"
                  className="nav-link hover-underline d-inline-block p-0">
                  1-888-710-8256
                </a>
                &nbsp; between 8:00AM - 12:00AM EST.
              </p>
            </>
          )}
          {/* Pre sale - single event */}
          {(props.eventState === EventStateEnum.PRESALE ||
            props.eventState === EventStateEnum.UPCOMING ||
            props.showClubPresale) &&
            props.clubPresaleDate != undefined &&
            props.clubPresaleDate != null &&
            new Date(props.clubPresaleDate).getFullYear() != 1 && (
              <p className="small my-3">
                Pre-sale for United Presidential Plus, United Club &amp; United Quest Cardmembers
                begins {props.clubPresaleDateText} and ends on {props.clubPresaleEndDateText}.
                {(props.eventState === EventStateEnum.PRESALE || props.showClubPresale) &&
                  props.presalePhoneNumber != undefined &&
                  props.presalePhoneNumber != null &&
                  props.presalePhoneNumber != '' &&
                  ` Call to RSVP: ${props.presalePhoneNumber}`}
              </p>
            )}

          {/* Social links */}
          {props.eventState !== EventStateEnum.PAST &&
            props.packages !== undefined &&
            props.packages.length && (
              <>
                <h3 className="h5">Connect on Social</h3>
                <div className="py-2 my-3 d-flex flex-row">
                  <FacebookShare
                    className="pe-lg-3 pe-md-0 pe-3 hover-fade"
                    eventURL={props.eventURL}
                  />
                  <TwitterShare
                    className="px-lg-3 px-2 hover-fade"
                    eventURL={props.eventURL}
                    title={props.title}
                  />
                </div>
              </>
            )}
        </div>
      </Col>

      <ContentModal
        header="Offer Terms &amp; Conditions"
        body={props.offerTermsAndConditions}
        show={showOfferTermsModal}
        onHide={() => setShowOfferTermsModal(false)}
      />
    </>
  );
};

export default EventAside;

EventAside.propTypes = {
  eventDate: PropTypes.string,
  eventDateText: PropTypes.string.isRequired,
  eventState: PropTypes.number.isRequired,
  eventURL: PropTypes.string.isRequired,
  isSweepstakes: PropTypes.bool.isRequired,
  sweepsstakesLeftRailCopy: PropTypes.string,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  locationText: PropTypes.string.isRequired,
  packages: PropTypes.array,
  isWaitList: PropTypes.bool,
  hideCalendarLinks: PropTypes.bool,
  hideCallToPurchase: PropTypes.bool,
  presalePhoneNumber: PropTypes.string,
  offerTermsAndConditions: PropTypes.string.isRequired,
  clubPresaleDate: PropTypes.string,
  clubPresaleEndDate: PropTypes.string,
  hasGallery: PropTypes.bool,
  registrationURL: PropTypes.string,
  sweepstakesEntryURL: PropTypes.string,
  phoneNumber: PropTypes.string,
  callCenterHours: PropTypes.string,
  clubPresaleDateText: PropTypes.string,
  clubPresaleEndDateText: PropTypes.string,
  onSaleDateLongText: PropTypes.string,
  sweepstakesStartDateLongText: PropTypes.string,
  showClubPresale: PropTypes.bool,
  paymentType: PropTypes.number,
  isEventFree: PropTypes.bool,
  onSaleDate: PropTypes.any
};
