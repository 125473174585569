import PropTypes from 'prop-types';

const Title = (props) => {
  return (
    <div className="pt-3">
      <p className="h5 d-none d-md-block text-uppercase">{props.category}</p>
      <h1 className="h2 d-none d-md-block">{props.title}</h1>
    </div>
  );
};

export default Title;

Title.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired
};
