import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col } from 'react-bootstrap';
import ButtonMain from './../ButtonMain/ButtonMain';

const ExperienceTile = (props) => {
  // In Umbraco, these are the Evergreen Tiles
  return (
    <>
      <Col className="mb-3">
        <Card className="shadow border-0 h-100">
          <Card.Img variant="top" src={props.imageURL} alt={props.imageAltText} />
          <Card.Body className="text-center d-flex flex-column">
            <img
              src={props.logoURL}
              alt={props.logoAriaText}
              className="mb-4 mt-2 mx-auto img-fluid w-100"
              style={{ maxWidth: '120px' }}
            />
            {/* TODO: add a field in Umbraco to actually pull this title in */}
            {/* <Card.Title as="h3" className="h5 text-uppercase">
              {props.title}
            </Card.Title> */}
            <p dangerouslySetInnerHTML={{ __html: props.copy }} />
            <ButtonMain
              className="position-relative w-100 mt-auto px-4"
              // if isOffsite is true, need position-relative on button
              isOffsite={props.linkIsOffsite}
              isChaseOffsite={!props.linkIsOffsite}
              label="Learn More"
              aria-label={`${props.linkAriaText}`}
              variant="primary"
              analyticsData={{
                event: 'travelTileClick'
              }}
              href={props.linkDestination}>
              Learn More
            </ButtonMain>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

ExperienceTile.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  imageURL: PropTypes.string,
  imageAltText: PropTypes.string,
  isEventPage: PropTypes.bool,
  linkAriaText: PropTypes.string,
  linkCopy: PropTypes.string,
  linkDestination: PropTypes.string,
  linkIsOffsite: PropTypes.bool.isRequired,
  logoURL: PropTypes.string,
  logoAriaText: PropTypes.string
};

export default ExperienceTile;
