import PropTypes, { any } from 'prop-types';
//import React, { useEffect, useState } from 'react';
import React from 'react';
import { GlobalContext } from './globalContext';
import { useContext, useCallback } from 'react';

export const FilterContext = React.createContext({
    getRegionFilterValues: any,
    getCategoryFilterValues: any,
    getPaymentFilterValues: any,
    getYearFilterValues: any,
    getMonthFilterValues: any,

    getRegionFilters: any,
    getCategoryFilters: any,
    getPaymentFilters: any,
    getYearFilters: any,
    getMonthFilters: any,

    setGlobalRegionFilters: () => { },
    setGlobalCategoryFilters: () => { },
    setGlobalPaymentFilters: () => { },
    setGlobalYearFilters: () => { },
    setGlobalMonthFilters: () => { },

    setGlobalCategoryFilterValues: () => { },
    setGlobalRegionFilterValues: () => { },
    setGlobalPaymentFilterValues: () => { },
    setGlobalYearFilterValues: () => { },
    setGlobalMonthFilterValues: () => { },

    getFilterCount: () => { },
    updateFilters: () => { },
    setFilter: () => { },
    resetFilter: () => { }

});

export const FilterContextProvider = (props) => {
    const [regionFilters, setRegionFilters] = React.useState([]);
    const [categoryFilters, setCategoryFilters] = React.useState([]);
    const [paymentFilters, setPaymentFilters] = React.useState([]);
    const [yearFilters, setYearFilters] = React.useState([]);
    const [monthFilters, setMonthFilters] = React.useState([]);

    const [regionFilterValues, setRegionFilterValues] = React.useState([]);
    const [categoryFilterValues, setCategoryFilterValues] = React.useState([]);
    const [paymentFilterValues, setPaymentFilterValues] = React.useState([]);
    const [yearFilterValues, setYearFilterValues] = React.useState([]);
    const [monthFilterValues, setMonthFilterValues] = React.useState([]);

    const globalData = useContext(GlobalContext);

    const updateFilter = (filter, label, idx) => {
        switch (filter) {
            case 'regions':
                updateFilterState(regionFilters, setRegionFilters, setRegionFilterValues, idx);
                break;
            case 'categories':
                updateFilterState(categoryFilters, setCategoryFilters, setCategoryFilterValues, idx);
                break;
            case 'paymentTypes':
                updateFilterState(paymentFilters, setPaymentFilters, setPaymentFilterValues, idx);
                break;
            case 'years':
                updateFilterState(yearFilters, setYearFilters, setYearFilterValues, idx);
                break;
            case 'months':
                updateFilterState(monthFilters, setMonthFilters, setMonthFilterValues, idx);
                break;
        }
    };

    const updateFilterState = (value, setFunction, setValuesFunction, idx) => {
        const valueCopy = value.slice();
        valueCopy[idx].isChecked = !valueCopy[idx].isChecked;
        setFunction(valueCopy);

        const selectedFilters = valueCopy.filter((f) => f.isChecked);
        if (selectedFilters.length !== 0) {
            setValuesFunction(selectedFilters.map((f) => f.label));
        } else {
            setValuesFunction([]);
        }
    };

    const filterCount = () =>{
        return regionFilterValues.length + categoryFilterValues.length + paymentFilterValues.length + yearFilterValues.length + monthFilterValues.length;
    }


    const setFilters = () => {
        setRegionFilters(createFilterObjects(globalData.api.regions));
        setCategoryFilters(createFilterObjects(globalData.api.categories));
        setPaymentFilters(createFilterObjects(globalData.api.paymentTypes));
        setYearFilters(createFilterObjects(globalData.api.years));
        setMonthFilters(createFilterObjects(globalData.api.months));
    }

    const createFilterObjects = (array) =>
        array.map((e, idx) => {
            return { label: e, isChecked: false, idx: idx };
        });


    const clearFilters = useCallback(() => {
        setRegionFilters(uncheckAll(regionFilters));
        setCategoryFilters(uncheckAll(categoryFilters));
        setPaymentFilters(uncheckAll(paymentFilters));
        setYearFilters(uncheckAll(yearFilters));
        setMonthFilters(uncheckAll(monthFilters));
        setRegionFilterValues([]);
        setCategoryFilterValues([]);
        setPaymentFilterValues([]);
        setYearFilterValues([]);
        setMonthFilterValues([]);
        globalData.pastEvents.resetEvents();
    }, [regionFilters, categoryFilters, paymentFilters, yearFilters, monthFilters]);

    const uncheckAll = (arr) => {
        const a2 = arr.slice();
        for (let i = 0; i < a2.length; ++i) {
            a2[i].isChecked = false;
        }

        return a2;
    };

    return <FilterContext.Provider value={{

        getMonthFilterValues: monthFilterValues,
        getRegionFilterValues: regionFilterValues,
        getCategoryFilterValues: categoryFilterValues,
        getPaymentFilterValues: paymentFilterValues,
        getYearFilterValues: yearFilterValues,

        getCategoryFilters: categoryFilters,
        getRegionFilters: regionFilters,
        getPaymentFilters: paymentFilters,
        getYearFilters: yearFilters,
        getMonthFilters: monthFilters,

        setGlobalRegionFilters: setRegionFilters,
        setGlobalCategoryFilters: setCategoryFilters,
        setGlobalPaymentFilters: setPaymentFilters,
        setGlobalYearFilters: setYearFilters,
        setGlobalMonthFilters: setMonthFilters,

        setGlobalRegionFilterValues: setRegionFilterValues,
        setGlobalCategoryFilterValues: setCategoryFilterValues,
        setGlobalPaymentFilterValues: setPaymentFilterValues,
        setGlobalYearFilterValues: setYearFilterValues,
        setGlobalMonthFilterValues: setMonthFilterValues,

        updateFilters: updateFilter,
        setFilter: setFilters,
        resetFilter: clearFilters,
        getFilterCount: filterCount

    }}>{props.children}</FilterContext.Provider>;
}
FilterContextProvider.propTypes = {
    children: PropTypes.object
};