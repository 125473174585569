//import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import FilterDisplay from '../components/FilterModal/FilterDisplay';
import EventTile from './../components/EventTile/EventTile';
import { getPastEvents } from './../utilities/api';
import { GlobalContext } from './../contexts/globalContext';
import { FilterContext } from '../contexts/filterContext';
import { WebIdDispatchContext } from '../contexts/navigationContext';
import { NagivationHighlightEnum } from '../enums/enums';
import { NavigationHighlightDispatchContext } from '../contexts/navigationContext';
import Hero from './../components/Hero/Hero';
import ButtonMain from './../components/ButtonMain/ButtonMain';
import { Helmet } from 'react-helmet';

const PastEventListingPage = () => {
  const [currentEvents, setCurrentEvents] = React.useState([]);

  const [eventContent, setEventContent] = useState(<></>);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [loadMoreTileIndex, setLoadMoreTileIndex] = useState(null);

  const [firstLoad, setFirstLoad] = useState(true);

  const globalData = useContext(GlobalContext);
  const filterData = useContext(FilterContext);
  const setWebId = useContext(WebIdDispatchContext);
  const setNavigationHighlightState = useContext(NavigationHighlightDispatchContext);

  const loadMore = () => {
    globalData.pastEvents.addEvents();
    setLoadMoreTileIndex(globalData.pastEvents.shownEvents);
  };

  useEffect(() => {
    if (
      globalData === undefined ||
      globalData.api.regions === undefined ||
      globalData.api.categories === undefined ||
      globalData.api.paymentTypes === undefined ||
      globalData.api.years === undefined ||
      globalData.api.months === undefined
    ) {
      return;
    }
    //if we have filterdata values, don't update
    if (
      filterData.getCategoryFilterValues.length === 0 &&
      filterData.getRegionFilterValues.length === 0 &&
      filterData.getPaymentFilterValues.length === 0 &&
      filterData.getYearFilterValues.length === 0 &&
      filterData.getMonthFilterValues.length === 0
    ) {
      filterData.setFilter();
    }

    setWebId(globalData.api.homepage.webId);
  }, [globalData, setWebId]);

  useEffect(() => {
    setNavigationHighlightState(NagivationHighlightEnum.PAST);

    setShowLoadMore();
  }, [setNavigationHighlightState]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
    if (currentEvents.length === 0) {
      getPastEvents(1)
        .then((eventRes) => eventRes.json())
        .then((eventResult) => {
          setCurrentEvents(eventResult);

          setFirstLoad(false);
        });
    }
  }, []);

  useEffect(() => {
    const filteredEvents = currentEvents.filter((f) => {
      const regionMatch =
        filterData.getRegionFilterValues.length === 0 ||
        f.regions.filter((value) => filterData.getRegionFilterValues.includes(value)).length > 0;

      const categoryMatch =
        filterData.getCategoryFilterValues.length === 0 ||
        filterData.getCategoryFilterValues.indexOf(f.category) !== -1;

      const paymentMatch =
        filterData.getPaymentFilterValues.length === 0 ||
        filterData.getPaymentFilterValues.indexOf(f.filterPaymentTypeString) !== -1;

      const yearMatch =
        filterData.getYearFilterValues.length === 0 ||
        f.filterYears.filter((value) => filterData.getYearFilterValues.includes(value.toString()))
          .length > 0;

      const monthMatch =
        filterData.getMonthFilterValues.length === 0 ||
        f.filterMonths.filter((value) => filterData.getMonthFilterValues.includes(value)).length >
          0;

      return regionMatch && categoryMatch && paymentMatch && yearMatch && monthMatch;
    });

    if (filteredEvents.length > 0) {
      if (filteredEvents.length > globalData.pastEvents.shownEvents) {
        setShowLoadMore(true);
      } else {
        setShowLoadMore(false);
      }

      setEventContent(
        filteredEvents
          .slice(0, globalData.pastEvents.shownEvents)
          .map((e, idx) => <EventTile key={`${idx}-event-tile`} id={`${idx}-event-tile`} {...e} />)
      );

      // Moves focus to the first newly loaded event tile upon Load More for ADA compliance, keyboard users
      if (loadMoreTileIndex) {
        let nextTileToFocus = document.getElementById(`${loadMoreTileIndex}-event-tile`);
        if (nextTileToFocus) {
          nextTileToFocus.focus();
        }
      }

      let formerEvent = localStorage.getItem('eventBackCache');
      let selector = document.querySelectorAll(`a[href*='${formerEvent}'`)[0];

      if (formerEvent && selector) {
        selector.focus();
        setTimeout(() => localStorage.clear(), 1000);
      }
    } else if (!firstLoad) {
      setShowLoadMore(false);
      setEventContent(
        <Col md={{ span: 10, offset: 1 }} className="text-center">
          <p className="h5" aria-live="polite">
            Sorry, there are no past events that match your search criteria.
          </p>
          <br />
          <Button
            variant="primary"
            className="font-neue-plak-med my-2"
            onClick={() => filterData.resetFilter()}>
            Clear All Filters
          </Button>
        </Col>
      );
    }
  }, [
    firstLoad,
    globalData.pastEvents.shownEvents,
    currentEvents,
    filterData.getRegionFilterValues,
    filterData.getCategoryFilterValues,
    filterData.getPaymentFilterValues,
    filterData.getYearFilterValues,
    filterData.getMonthFilterValues,
    globalData.api.homepage,
    filterData.resetFilter
  ]);

  return (
    <>
      <Helmet>
        <title>Past Experiences | United Card Events from Chase</title>
        <meta
          name="description"
          content="United Card Events from Chase offers access to once-in-a-lifetime experiences exclusively for select Chase Cardmembers."
        />
      </Helmet>
      {globalData !== undefined && globalData.api.homepage !== undefined && (
        <Hero homepage={globalData.api.homepage} isPastEvents={true} />
      )}
      {
        <>
          <FilterDisplay
            showPaymentTypes={false}
            showDates={true}
            regions={filterData.getRegionFilters}
            categories={filterData.getCategoryFilters}
            paymentTypes={filterData.getPaymentFilters}
            years={filterData.getYearFilters}
            months={filterData.getMonthFilters}
            updateFilter={filterData.updateFilters}
            clearFilters={() => filterData.resetFilter()}
            countFilters={filterData.getFilterCount}
          />
        </>
      }
      {/* Event Tiles */}

      <Container id="listing-page" fluid as="main" className="bg-beige pb-5">
        <Row>
          <Col>
            <Container className="px-1">
              <Row className="gy-4">
                <h2 className="d-lg-none h6 font-neue-plak-med mt-3">Past Experiences</h2>
                <p aria-live="polite" className="visually-hidden">
                  {`There are ${
                    eventContent.length == undefined ? 'zero' : eventContent.length
                  } events based on your selected filters`}
                </p>
                {eventContent}
              </Row>
              {showLoadMore && (
                <Row className="mt-5">
                  <ButtonMain
                    onClick={() => loadMore()}
                    label="Load More"
                    aria-label={`Load more Past Experiences. You are currently viewing ${eventContent.length} events`}
                    className="col-auto mx-auto"
                  />
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PastEventListingPage;

// PastEventListingPage.propTypes = {

// };
