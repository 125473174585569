import { VideoPlay, VideoPause } from '../Icons/Icons';
import { Container, Button } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion';

const Hero = ({ homepage }) => {
  const videoRef = useRef();
  const handlePlay = () => {
    videoRef.current.play();
  };
  const handlePause = () => {
    videoRef.current.pause();
  };
  const prefersReducedMotion = usePrefersReducedMotion();

  const [isActive, setActive] = useState(!prefersReducedMotion);
  const ToggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    if (isActive) {
      handlePlay();
    } else {
      handlePause();
    }
  }, [isActive]);

  return (
    <Container
      fluid
      as="section"
      role="banner"
      id="hero-vid"
      className="bg-white bg-lg-transparent py-5 position-relative px-md-0 overflow-hidden">
      <video
        src={homepage.brollFootage}
        muted
        loop
        ref={videoRef}
        playsInline={!prefersReducedMotion}
        type="video/mp4"
        aria-hidden="true"
        className="d-none d-lg-block w-100 position-absolute top-0"
        style={{ zIndex: '-1' }}
      />
      <Button
        onClick={ToggleClass}
        variant="link"
        className="d-none d-lg-block position-absolute top-0 p-2"
        aria-label={`Video is currently ${isActive ? 'playing' : 'paused'}, click to ${
          isActive ? 'pause' : 'play'
        }`}
        id="vid-btn"
        style={{ right: '5px', top: '5px' }}>
        {isActive ? <VideoPause /> : <VideoPlay />}
      </Button>
      <Container className="text-center text-dark-grey text-lg-white pb-lg-1">
        <h1 className="font-neue-plak-med">Welcome to United Card Events from Chase.</h1>
        <p className="h5 py-3">Purchase once-in-a-lifetime experiences by using your United Card</p>

        <p>
          Unforgettable experiences exclusively for United MileagePlus® cardmembers. Formerly Inside
          Access from Chase, these exciting programs make it possible to explore your passions,
          discover new ones, and make memories of a lifetime.
        </p>
      </Container>
    </Container>
  );
};

export default Hero;

Hero.propTypes = {
  homepage: PropTypes.any,
  isPastEvents: PropTypes.bool
};
