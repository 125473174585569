import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ThumbnailImage from './ThumbnailImage';
import { Button, Carousel, Modal, Row, Col, Nav } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Facebook from './../../assets/icons/icon-facebook.png';

const Gallery = (props) => {
  const [searchParams] = useSearchParams();
  const [visibleGalleryIndex, setVisibleGalleryIndex] = useState(0);
  const [isAriaCurrent, setAriaCurrent] = useState(0);
  const [showModal, setShowModal] = useState(-1);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [shareImage, setShareImage] = useState(undefined);

  const galleryExists =
    props.galleryContent !== undefined &&
    props.galleryContent !== null &&
    props.galleryContent != '';

  const getSelectedImageSrc = () =>
    props.galleryContent[visibleGalleryIndex].images[selectedImageIndex].src;

  const facebookShare = (e) => {
    e.preventDefault();

    const shareUrl = `https://${window.document.location.host}${
      window.document.location.pathname
    }?share=${getSelectedImageSrc()}`;

    const finalURL = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}&hashtag=UnitedCardEventsfromChase`;

    window.open(finalURL);
  };

  const fileDownload = (e) => {
    e.preventDefault();
    window.open(getSelectedImageSrc());
  };

  const highlightSelectedGallery = (idx) =>
    idx === visibleGalleryIndex ? 'text-purple border-bottom border-purple' : null;
  const addAriaCurrentPage = (idx) => (idx === isAriaCurrent ? 'page' : null);

  useEffect(() => {
    // Parse query string and set potential share links
    if (galleryExists) {
      const incomingShareImage =
        searchParams.get('share') === undefined || searchParams.get('share') === null
          ? searchParams.get('imageid')
          : searchParams.get('share');

      if (incomingShareImage !== undefined && incomingShareImage !== null) {
        let found = false;
        for (let i = 0; !found && i < props.galleryContent.length; ++i) {
          const idx = props.galleryContent[i].images.findIndex(
            (img) => img.src.split('.')[0] == incomingShareImage
          );
          if (idx > -1) {
            found = true;
            setVisibleGalleryIndex(i);
            setShowModal(idx);
            setSelectedImageIndex(idx);

            const g = props.galleryContent[i];
            setShareImage(`${g.images[idx].src}`);
          }
        }
      }
    }
  }, [galleryExists, props.galleryContent, searchParams]);

  return (
    <>
      {shareImage !== undefined && (
        <Helmet>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={shareImage} />
        </Helmet>
      )}
      <Row className="mb-5">
        {galleryExists && props.galleryContent.length > 1 && (
          <Col xs={12} as="div" className="flex-row border-top border-bottom mt-2 py-2 mb-3">
            <Nav as="ul">
              {props.galleryContent.map((val, idx) => (
                <>
                  <li>
                    <Nav.Link
                      key={`${val.galleryName}-${idx}`}
                      as="button"
                      variant="link"
                      aria-controls={`gallery-collection-${idx}`}
                      className={`px-0 me-3 nav-link-hover-decoration btn btn-link ${highlightSelectedGallery(
                        idx
                      )}`}
                      aria-current={addAriaCurrentPage(idx)}
                      text-purple
                      border-bottom
                      border-purple
                      onClick={() => {
                        setVisibleGalleryIndex(idx);
                        setAriaCurrent(idx);
                      }}>
                      {val.galleryName}
                    </Nav.Link>
                  </li>
                </>
              ))}
            </Nav>
          </Col>
        )}
        <p className="visually-hidden">
          Click to open a dialog that allows you to view a larger image, download the full size
          image, or share the image.
        </p>
        <Col as="ul" className="px-md-0 text-sm-start text-center">
          {galleryExists && (
            <>
              {props.galleryContent[visibleGalleryIndex].images.map((val, idx) => (
                <>
                  <li className="d-inline-block">
                    <ThumbnailImage
                      key={`${visibleGalleryIndex}-${idx}`}
                      alt={
                        val.alt == ''
                          ? `Gallery Image ${idx} of ${props.galleryContent[visibleGalleryIndex].images.length}`
                          : val.alt
                      }
                      src={val.thumbnail}
                      onClick={() => {
                        setSelectedImageIndex(idx);
                        setShowModal(idx);
                      }}
                    />
                  </li>
                </>
              ))}

              <Modal size="lg" show={showModal > -1} onHide={() => setShowModal(-1)}>
                <Modal.Header
                  closeButton
                  closeLabel="Close Modal"
                  className="border-0"></Modal.Header>
                <Modal.Body className="p-0">
                  <Carousel
                    defaultActiveIndex={showModal}
                    indicators={false}
                    aria-live="assertive"
                    interval={null}
                    onSelect={(eventKey) => {
                      setSelectedImageIndex(eventKey);
                    }}>
                    {galleryExists &&
                      props.galleryContent[visibleGalleryIndex].images.map((img, i) => {
                        const altText = img.alt
                          ? img.alt
                          : `Gallery Image ${i + 1} of ${
                              props.galleryContent[visibleGalleryIndex].images.length
                            }`;
                        const key = img.src;

                        return (
                          <Carousel.Item
                            key={key}
                            aria-hidden={i === selectedImageIndex ? false : true}>
                            <img
                              src={key}
                              alt={altText}
                              aria-label={altText}
                              className="col-auto mx-auto d-flex img-fluid"
                            />
                          </Carousel.Item>
                        );
                      })}
                  </Carousel>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                  <Button
                    variant=""
                    bsPrefix="btn-link"
                    className="font-neue-plak-med bg-transparent border-0"
                    aria-label="Download Photo"
                    onClick={(e) => fileDownload(e)}>
                    Download Photo
                  </Button>
                  <span className="font-weight-bold text-smoke-900" aria-hidden="true">
                    |
                  </span>
                  <a
                    className="p-0 hover-fade"
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => facebookShare(e)}
                    aria-label="Facebook: Opens in a new page or window.">
                    <img style={{ width: 25 }} src={Facebook} />
                  </a>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Gallery;

Gallery.propTypes = {
  galleryContent: PropTypes.array.isRequired
};
