import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from './../components/Breadcrumbs/Breadcrumbs';
import Gallery from './../components/Gallery/Gallery';
import { getSingleEventData } from './../utilities/api';
import { NavigationHighlightDispatchContext } from '../contexts/navigationContext';
import { NagivationHighlightEnum } from '../enums/enums';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const GalleryPage = () => {
  const { series, id } = useParams();
  const [eventData, setEventData] = React.useState(undefined);
  const setNavigationHighlightState = useContext(NavigationHighlightDispatchContext);
  const [show404, setShow404] = React.useState(false);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);

    setNavigationHighlightState(NagivationHighlightEnum.PAST);
    getSingleEventData(series !== undefined ? `${series}/${id}` : id)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.galleries) {
          setShow404(true);
          setEventData(undefined);
        } else {
          setShow404(false);
          setEventData(data);
        }
      });
  }, [id, series, setNavigationHighlightState]);

  return (
    <>
      {eventData !== undefined && (
        <Helmet>
          <title>Event Gallery | {eventData.title}</title>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@Chase" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={eventData.title} />
          <meta
            property="og:url"
            content={`https://www.unitedcardevents.com${eventData.eventURL}gallery`}
          />

          <meta property="og:image" content={eventData.tileImage.imageURL} />
          <meta property="og:description" content={eventData.metaDescription} />
          <meta property="og:site_name" content="United Card Events from Chase" />
        </Helmet>
      )}
      {!show404 && (
        <Container as="main">
          {eventData !== undefined && (
            <>
              <Breadcrumbs isPhotoGallery={true} {...eventData} />
              <p className="h5 text-uppercase">{eventData.category}</p>
              <h1 className="h2 ">{eventData.title}</h1>
              <Gallery galleryContent={eventData.galleries} />
            </>
          )}
        </Container>
      )}
      {show404 && (
        <Container as="main">
          {eventData !== undefined && <Breadcrumbs {...eventData} />}
          <Row className="m-5">
            <h2 className="text-center">Page not found.</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </Row>
        </Container>
      )}
    </>
  );
};

export default GalleryPage;
