import React from 'react';
import ChaseDefault from './../../assets/logos/logo-chase.png';
import ChaseWhite from './../../assets/logos/logo-chase-white.png';
import ChaseDark from './../../assets/logos/logo-chase-black.png';
import UCE from './../../assets/logos/logo-uce.png';
import LHRC from './../../assets/logos/logo-lhrc.svg';
import United from './../../assets/logos/logo-united.svg';
import pushDataLayer from '../../utilities/analytics';

const ChaseLogo = () => {
  return (
    <>
      <a
        href="https://www.chase.com/"
        target="_blank"
        rel="noreferrer"
        aria-label="Opens the Chase homepage in a new window"
        className="btn btn-link px-0">
        <img className="logo-default" src={ChaseDefault} alt="Chase logo" />
        <img className="logo-hcontrast" src={ChaseWhite} alt="Chase logo" />
        <img className="logo-bwcontrast" src={ChaseDark} alt="Chase logo" />
      </a>
    </>
  );
};

const UCELogo = () => {
  return (
    <>
      <a href="/" className="pt-2 me-auto">
        <img
          src={UCE}
          alt="United Card Events from Chase homepage"
          style={{ maxWidth: '120px' }}
          onClick={() =>
            pushDataLayer({
              event: 'navigationClick',
              attributes: {
                location: 'header'
              }
            })
          }
        />
      </a>
    </>
  );
};

const LHRCLogo = () => {
  return (
    <>
      <img
        className={'pb-3 pt-1 py-lg-4'}
        src={LHRC}
        alt="The Luxury Hotel & Resort Collection"
        style={{ maxHeight: '70px' }}
      />
    </>
  );
};

const UnitedLogo = () => {
  return (
    <>
      <img
        className={'pb-3 pt-1 py-lg-4'}
        src={United}
        alt="United"
        style={{ maxHeight: '70px' }}
      />
    </>
  );
};

export const Logos = () => {
  return (
    <div className="">
      <ChaseLogo />
      <UCELogo />
      <LHRCLogo />
      <UnitedLogo />
    </div>
  );
};

export { ChaseLogo, UCELogo, LHRCLogo, UnitedLogo };

export default Logos;
