import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { NagivationHighlightEnum } from '../enums/enums';

const WebIdContext = React.createContext('');
const WebIdDispatchContext = React.createContext(() => {});

const NavigationHighlightContext = React.createContext(NagivationHighlightEnum.NONE);
const NavigationHighlightDispatchContext = React.createContext(() => {});

const NavigationContextProvider = (props) => {
  const [webId, setWebId] = useState('');
  const [navigationHighlightState, setNavigationHighlightState] = useState(
    NagivationHighlightEnum.NONE
  );

  return (
    <NavigationHighlightContext.Provider value={navigationHighlightState}>
      <NavigationHighlightDispatchContext.Provider value={setNavigationHighlightState}>
        <WebIdContext.Provider value={webId}>
          <WebIdDispatchContext.Provider value={setWebId}>
            {props.children}
          </WebIdDispatchContext.Provider>
        </WebIdContext.Provider>
      </NavigationHighlightDispatchContext.Provider>
    </NavigationHighlightContext.Provider>
  );
};

NavigationContextProvider.propTypes = {
  children: PropTypes.object
};

export {
  WebIdContext,
  WebIdDispatchContext,
  NavigationContextProvider,
  NavigationHighlightContext,
  NavigationHighlightDispatchContext
};
