import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

export const ContentModal = (props) => {
  const headerID = `${props.header.replace(/\s+/g, '-').replace(/&/g, 'and').toLowerCase()}`;

  return (
    <Modal size="lg" show={props.show} onHide={props.onHide} aria-labelledby={`${headerID}`}>
      {props.header !== undefined && (
        <Modal.Header className="align-items-start" closeButton>
          <Modal.Title>
            <h1 className="h2 px-2" id={headerID}>
              {props.header}
            </h1>
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="px-4">
        <span dangerouslySetInnerHTML={{ __html: props.body }}></span>
      </Modal.Body>
    </Modal>
  );
};

ContentModal.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default ContentModal;
